import { Feature } from '@/shared/types/generic';

const getFeatureToggles = () => {
  return {
    features: [
      Feature.METRIC_PERFORMANCE_DASHBOARD,
      Feature.ERROR_HANDLING,
      Feature.PUBLIC_DISPLAY_MANAGER,
      Feature.DUPLICATE_CHALLENGE_MANAGER,
      Feature.SURVEY_CUSTOM_QUESTIONS,
      Feature.SURVEY_DEFAULT_QUESTIONS,
      Feature.DASHBOARD_MANAGER,
      Feature.KUDOS,
      Feature.FTX,
      Feature.PUBLIC_DISPLAY_LIST,
    ],
  };
};

export default getFeatureToggles;

import { Locale, QuasarLang } from '@/shared/types/generic';

export default function getQuasarLang(language: Locale): string {
  const QuasarLanguage: { [key in Locale]: string } = {
    [Locale.PT]: QuasarLang.PT,
    [Locale.US]: QuasarLang.EN_US,
    [Locale.DE]: QuasarLang.DE_DE,
    [Locale.ES]: QuasarLang.ES_ES,
  };

  return QuasarLanguage[language];
}

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { FTXSlide } from '@/shared/types/ftx';
import { computed } from 'vue';

interface Props {
  slide: FTXSlide;
}

const props = defineProps<Props>();

const { t } = useI18n();

const hasImageSlide = computed<boolean>(
  () => props.slide?.imageSlide !== undefined,
);
</script>

<template>
  <component
    :is="slide.imageSlide"
    v-if="hasImageSlide"
    class="ftx-slide__image"
  />
  <span v-if="!hasImageSlide" class="ftx-slide__get-started">
    {{ t('ftx.getStarted') }}
  </span>
  <span class="ftx-slide__title">{{ slide.title }}</span>
  <span class="ftx-slide__description">{{ slide.description }}</span>
</template>

<style scoped lang="scss">
.ftx-slide__image {
  margin-bottom: 40px;
  min-height: 150px;
}

.ftx-slide__get-started {
  font-size: 12px;
  color: $gray-400;
  line-height: 18px;
  margin-bottom: 16px;
}

.ftx-slide__title {
  font-size: 24px;
  color: $gray-800;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

.ftx-slide__description {
  font-size: 18px;
  color: $gray-600;
  line-height: 32px;
  margin-bottom: 40px;
}
</style>

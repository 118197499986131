import featureTogglesService from '@/shared/services/featureTogglesService/featureTogglesService';
import notify from '@/shared/helpers/notify/notify';
import i18n from '@/plugins/i18n/i18n';
import { Feature } from '@/shared/types/generic';
import { ref } from 'vue';

const featureList = ref<Feature[]>([]);

export default function useFeatures() {
  const loadFeatures = async (): Promise<void> => {
    try {
      featureList.value.push(
        ...((await featureTogglesService.getFeatureToggles())?.features ?? []),
      );
    } catch (e) {
      notify(i18n.global.t('errors.general.wrong'), 'danger');
    }
  };

  const isFeatureEnabled = (feature: Feature): boolean => {
    return featureList.value.includes(feature);
  };

  const $reset = () => {
    featureList.value = [];
  };

  return { loadFeatures, isFeatureEnabled, $reset };
}

import {
  RegisterFile,
  NuvoFileValidationRow,
  LoadFilesRequest,
  LoadFilesResponse,
} from '@/shared/types/files';
import qs from 'qs';
import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import { AxiosResponse } from 'axios';

export default {
  getFiles(request: LoadFilesRequest): Promise<ApiResponse<LoadFilesResponse>> {
    return axios.get('/ingestors/files', {
      params: {
        page: request.page,
        size: request.size,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  validateFile(file: NuvoFileValidationRow[]): Promise<AxiosResponse> {
    return axios.post('/ingestors/files/validation', file);
  },

  registerFile(registerFile: RegisterFile): Promise<{
    metricsimported: number;
    metricsWithErrors: number;
  }> {
    return axios.post('/ingestors/files', registerFile);
  },

  deleteFile(reference: string): Promise<{ message: string }> {
    return axios.delete(`/ingestors/files/${reference}`);
  },

  downloadFile(reference: string): Promise<ArrayBuffer> {
    return axios.get(`/ingestors/files/${reference}/download`, {
      responseType: 'arraybuffer',
    });
  },
};

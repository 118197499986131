import { DatePeriod, ResponseMetadata } from '@/shared/types/generic';
import {
  EditProfileRequestBody,
  LanguageCode,
  ManagerProfile,
  ManagerProfileSite,
} from '@/shared/types/profile';

export const profile: ManagerProfile = {
  user: {
    termsConditions: true,
    privacyPolicy: true,
    changePassword: false,
    reference: 'teste',
    name: 'Hannah Arendt',
    username: 'Hannah Arendt',
    email: 'hannahuser@tribehero.com',
    orgName: 'Company X',
    joinedAt: 1623931200000,
    orgUserId: 'USERIDTEST01',
    userDescription: 'I am a user',
  },
  userConfig: {
    language: 'en-US',
    userLanguage: 'en-US',
    surveyPopUp: true,
    showFtx: false,
    currencyCode: 'EUR',
    exchangeRate: 1000,
    enrollmentTime: DatePeriod.MINUTE * 5,
  },
};

export const editProfile: EditProfileRequestBody = {
  name: 'Test',
  orgUserId: 'org id test',
  siteReference: 'test',
  managedSitesReferences: [],
  managedTeamsReferences: [],
  roleLevelReference: 'test',
};

export const profileSite: ManagerProfileSite = {
  site: {
    name: 'Lisbon Site',
    reference: '38ce1d5f-3bda-4313-a932-4fcdaa59947a',
    city: 'Lisbon',
    country: 'Portugal',
    timezone: {
      utcOffset: 'UTC+4',
      ianaTimezone: 'Europe/Lisbon',
    },
  },
};

export const mockLanguages = (): LanguageCode[] => [
  { code: 'en-US' },
  { code: 'pt-PT' },
  { code: 'de-DE' },
];

export const metadata: ResponseMetadata = {
  totalItems: 8,
  lastUpdated: 1595030400000,
};

export const getMockLanguages = () => {
  return {
    metadata,
    results: [{ code: 'en-US' }, { code: 'pt-PT' }, { code: 'de-DE' }],
  };
};

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 15V16H16V15L2 15ZM4.3714e-08 16C9.19962e-08 17.1046 0.895431 18 2 18H16C17.1046 18 18 17.1046 18 16V15C18 13.8954 17.1046 13 16 13L2 13C0.89543 13 -4.82823e-08 13.8954 0 15L4.3714e-08 16Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 6L10 12H8L8 6H10Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 6C10.1046 6 11 5.10457 11 4C11 2.89543 10.1046 2 9 2C7.89543 2 7 2.89543 7 4C7 5.10457 7.89543 6 9 6ZM9 8C11.2091 8 13 6.20914 13 4C13 1.79086 11.2091 0 9 0C6.79086 0 5 1.79086 5 4C5 6.20914 6.79086 8 9 8Z"
      :fill="props.color"
    />
  </svg>
</template>

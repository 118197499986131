<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.15093 0.370479C8.66248 0.0171324 9.33752 0.0171317 9.84907 0.370479L17.3491 5.55095C17.7565 5.83239 18 6.29767 18 6.79487V16.5965C18 17.4299 17.3284 18.1055 16.5 18.1055H12C11.1716 18.1055 10.5 17.4299 10.5 16.5965V12.0697H7.5V16.5965C7.5 17.4299 6.82843 18.1055 6 18.1055H1.5C0.671572 18.1055 0 17.4299 0 16.5965V6.79487C0 6.29767 0.243477 5.83239 0.650928 5.55095L8.15093 0.370479ZM9 2.22453L2 7.05964V16.0936H5.5V11.5668C5.5 10.7334 6.17157 10.0578 7 10.0578H11C11.8284 10.0578 12.5 10.7334 12.5 11.5668V16.0936H16V7.05964L9 2.22453Z"
      :fill="props.color"
    />
  </svg>
</template>

import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import { GetRequestParameters } from '@/shared/types/requests';
import {
  CreateSiteRequestParameters,
  EditSitesRequestBody,
  SiteLocation,
  Sites,
  SitesCount,
  SitesSimplified,
} from '@/shared/types/settings';

export default {
  removeSites(reference: string) {
    return axios.delete(`/sites/${reference}`);
  },

  getSites({
    page,
    size,
    sortBy,
    orderBy,
    queryBy,
  }: GetRequestParameters = {}): Promise<ApiResponse<{ sites: Sites[] }>> {
    return axios.get('/sites', {
      params: {
        page,
        size,
        sort_by: sortBy,
        order_by: orderBy,
        query_by: queryBy,
      },
    });
  },

  getSitesSimplified(
    disableFilter?: boolean,
  ): Promise<ApiResponse<{ sites: SitesSimplified[] }>> {
    return axios.get('/sites/simplified', {
      params: {
        disableFilter,
      },
    });
  },

  createSite(newSite: CreateSiteRequestParameters): Promise<void> {
    return axios.post('/sites', newSite);
  },

  getLocations(
    city: string,
  ): Promise<ApiResponse<{ locations: SiteLocation[] }>> {
    return axios.get(`/locations?cityName=${city}`);
  },

  getTimezone(
    lat: number,
    long: number,
  ): Promise<{ utcOffset: string; ianaTimezone: string }> {
    return axios.get(`locations/timezone?latitude=${lat}&longitude=${long}`);
  },

  editSites(
    reference: Sites['reference'],
    requestBody: EditSitesRequestBody,
  ): Promise<void> {
    return axios.patch(`/sites/${reference}`, requestBody);
  },

  getCounters(): Promise<SitesCount> {
    return axios.get('/sites/count');
  },
};

import { StepsButtonsLabel } from '@/shared/types/generic';
import { ref, computed } from 'vue';

export default function useSteps(
  stepsList: number,
  lastButtonLabels: StepsButtonsLabel | undefined,
  initialStep = 0,
) {
  const stepIndex = ref(initialStep);

  const isLastStep = computed<boolean>(() => {
    return stepIndex.value === stepsList - 1;
  });

  const isFirstStep = computed<boolean>(() => {
    return stepIndex.value === initialStep;
  });

  const nextStep = () => {
    if (!isLastStep.value) {
      stepIndex.value += 1;
    }
  };

  const previousStep = () => {
    if (!isFirstStep.value) {
      stepIndex.value -= 1;
    }
  };

  const labelNextAndLastButton = computed<string>(() => {
    if (lastButtonLabels) {
      return isLastStep.value ? lastButtonLabels.last : lastButtonLabels.next;
    }
    return '';
  });

  const goToStep = (step: number) => {
    stepIndex.value = step;
  };

  return {
    stepIndex,
    isLastStep,
    isFirstStep,
    nextStep,
    previousStep,
    labelNextAndLastButton,
    goToStep,
    stepsList,
  };
}

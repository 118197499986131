<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 2C1 0.895431 1.89543 0 3 0H15C16.1046 0 17 0.89543 17 2V6H15V2H3V6H1V2ZM9.17314 6.06996C9.82778 4.51038 12.0024 4.41704 12.7883 5.9148L13.8824 8H18V10H13.8824C13.1389 10 12.4568 9.58759 12.1114 8.92924L11.0173 6.84404L8.88241 11.93C8.22777 13.4896 6.05312 13.583 5.26726 12.0852L4.17318 10H0V8H4.17318C4.91665 8 5.59877 8.41241 5.9442 9.07076L7.03828 11.156L9.17314 6.06996ZM3 12L3 16L15 16V12H17V16C17 17.1046 16.1046 18 15 18H3C1.89543 18 1 17.1046 1 16V12H3Z"
      :fill="props.color"
    />
  </svg>
</template>

import {
  ChallengeDuelsResults,
  ChallengeLeaderboardResponse,
} from '@/shared/types/challenges';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';

const metadata: ResponseMetadata = {
  totalItems: 3,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export default (): ApiResponse<ChallengeLeaderboardResponse> => {
  const leaderboardDuels = [
    {
      position: 2,
      name: 'Team 1',
      username: 'Team 1',
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 1250,
      points: 3109456,
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      coins: 10,
      result: ChallengeDuelsResults.TIE,
    },
    {
      position: 1,
      name: 'Team 2',
      username: 'Team 2',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 1112,
      points: 210945,
      coins: 0,
      result: ChallengeDuelsResults.TIE,
    },
  ];
  return {
    metadata,
    results: {
      userLeaderboards: leaderboardDuels,
      lastUpdateDate: new Date('2022-11-09T12:33').getTime(),
      nextClaimCycleDate: new Date('2022-11-14T12:33').getTime(),
    },
  };
};

import IconHome from '@/assets/icons/icon-home.vue';
import IconChallenges from '@/assets/icons/icon-challenges.vue';
import IconLeaderboard from '@/assets/icons/icon-leaderboard.vue';
import IconStore from '@/assets/icons/icon-store.vue';
import IconUsers from '@/assets/icons/icon-users.vue';
import IconMetrics from '@/assets/icons/icon-metrics.vue';
import IconDataImport from '@/assets/icons/icon-data-import.vue';
import IconMenuPublicDisplay from '@/assets/icons/icon-menu-public-display.vue';
import IconSurvey from '@/assets/icons/icon-survey.vue';
import { NavigationLink, Routes } from '@/shared/types/generic';
import { PermissionCode } from '@/shared/types/roles';

const navigationItems: NavigationLink[] = [
  {
    to: Routes.HOME,
    icon: IconHome,
    labelKey: 'nav.home',
    testId: 'nav-home-link',
    id: 'vaibe-ftx__home',
    permissions: [PermissionCode.DASHBOARD_READ],
  },
  {
    to: Routes.CHALLENGES,
    icon: IconChallenges,
    labelKey: 'nav.games',
    testId: 'nav-games-link',
    id: 'vaibe-ftx__challenges',
    permissions: [PermissionCode.CHALLENGE_READ],
  },
  {
    to: Routes.LEADERBOARD,
    icon: IconLeaderboard,
    labelKey: 'nav.leaderboard',
    testId: 'nav-leaderboard-link',
    id: 'vaibe-ftx__leaderboard',
    permissions: [PermissionCode.LEADERBOARD_READ],
  },
  {
    to: Routes.STORE,
    icon: IconStore,
    labelKey: 'nav.store',
    testId: 'nav-store-link',
    id: 'vaibe-ftx__store',
    permissions: [
      PermissionCode.STORE_READ_ITEMS,
      PermissionCode.PURCHASE_ACTIVITY_READ,
    ],
  },
  {
    to: Routes.USERS,
    icon: IconUsers,
    labelKey: 'nav.users',
    testId: 'nav-users-link',
    id: 'vaibe-ftx__users',
    permissions: [PermissionCode.USERS_READ, PermissionCode.TEAMS_READ],
  },
  {
    to: Routes.METRICS,
    icon: IconMetrics,
    labelKey: 'nav.metrics',
    testId: 'nav-metric-link',
    id: 'vaibe-ftx__metrics',
    permissions: [PermissionCode.METRICS_READ, PermissionCode.CATEGORY_READ],
  },
  {
    to: Routes.DATA_IMPORT,
    icon: IconDataImport,
    labelKey: 'nav.dataInput',
    testId: 'nav-data-input-link',
    id: 'vaibe-ftx__data',
    permissions: [PermissionCode.DATA_IMPORT_READ],
  },
  {
    to: Routes.PUBLIC_DISPLAYS,
    icon: IconMenuPublicDisplay,
    labelKey: 'nav.publicDisplays',
    testId: 'nav-public-displays',
    id: 'vaibe-ftx__public-displays',
    permissions: [PermissionCode.PUBLIC_DISPLAYS_READ],
  },
  {
    to: Routes.SURVEYS,
    icon: IconSurvey,
    labelKey: 'nav.surveys',
    testId: 'nav-surveys',
    id: 'vaibe-ftx__surveys',
    permissions: [PermissionCode.SURVEYS_READ],
  },
];

export default navigationItems;

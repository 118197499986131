import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c8ccd1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirstTimeExperience = _resolveComponent("FirstTimeExperience")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppGlobalConfirmationModal = _resolveComponent("AppGlobalConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.requiresAuthentication)
      ? (_openBlock(), _createBlock(_component_AppLayout, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.showFTXModals)
              ? (_openBlock(), _createBlock(_component_FirstTimeExperience, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _createVNode(_component_AppLoader, {
      state: _ctx.sharedStore.loaderState,
      target: _ctx.sharedStore.loaderTarget,
      transparent: _ctx.sharedStore.loaderTransparent,
      text: _ctx.sharedStore.loaderText
    }, null, 8, ["state", "target", "transparent", "text"]),
    _createVNode(_component_AppLoader, {
      state: _ctx.isLoading,
      target: "#app",
      transparent: false
    }, {
      text: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('roles.update.permissions')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('roles.update.account')), 1)
        ])
      ]),
      _: 1
    }, 8, ["state"]),
    _createVNode(_component_AppGlobalConfirmationModal)
  ], 64))
}
import { rest } from 'msw';
import basePath from '@/mocks/helpers/basePath';
import {
  editProfile,
  profileSite,
  profile,
  getMockLanguages,
} from '@/mocks/profile/mockProfile';
import {
  getChallengesList,
  getMockRecurrencyConfigs,
  getChallengeCompletionDetails,
  getMockChallengeParticipants,
  getChallengeDetails,
  createChallenge,
} from '@/mocks/challenges/mockChallenges';
import {
  getChartData,
  getMetrics,
  getMockedMetricsCounters,
} from '@/mocks/metrics/mockMetrics';
import { getLeaderboard } from '@/mocks/leaderboard/mockLeaderboard';
import {
  getCategories,
  getMockedCategories,
} from '@/mocks/categories/mockCategories';
import {
  getItemTypes,
  getPurchases,
  getStoreItems,
} from '@/mocks/store/mockStore';
import { LoadManagersRequest, LoadUsersRequest } from '@/shared/types/user';
import {
  getManagers,
  getMockedUserCounters,
  getUsers,
  mockGetAllValidOperators,
} from '@/mocks/user/mockUser';
import { LoadGameRequest } from '@/shared/types/challenges';
import { getFiles } from '@/mocks/files/mockFiles';
import { getChallengeImages } from './challenges/mockChallengeImages';
import { getChallengeTypes } from './challenges/mockChallengeTypes';
import getCategorizedMetrics from './metrics/mockCategorizedMetrics';
import { getChallengeGoals } from './challenges/mockChallengeGoals';
import mockChallengePrizeDistributionCoins from './challenges/mockChallengePrizeDistributionCoins';
import mockChallengeLeaderboardDuels from './challenges/mockChallengeLeaderboardDuels';
import getUnitOfMeasure from './unitMeasure/mockUnitMeasure';
import {
  mockGetSurveysData,
  mockGetSurveysStatus,
  mockGetSurveysQuestionData,
} from './surveys/mockSurveys';
import { mockGetTeams } from './teams/mockTeams';
import { getSurveyCompletionDetails, mockGetStatus } from './status/mockStatus';

import { mockKudoTypes } from './kudos/mockKudos';
import getFeatureToggles from './feature/mockFeatureToggles';
import {
  getMockedChallengeSites,
  getSites,
  getSitesSimplified,
} from './sites/mockSites';
import {
  mockGetChallengesData,
  mockGetChallengeKpis,
  getMockedMetricChart,
} from './dashboard/mockDashboard';
import { mockRoleLevels, mockRoleLevelsSimplified } from './roles/mockRoles';
import mockChallengePrizeDistributionPoints from './challenges/mockChallengePrizeDistributionPoints';
import {
  createPublicDisplay,
  mockGetPublicDisplays,
} from './publicDisplays/mockPublicDisplays';

const handlers = [
  rest.get(basePath('/profiles'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(profile));
  }),
  rest.patch(basePath('/profiles/manager'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(editProfile));
  }),

  rest.get(basePath('/profiles/manager/site'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(profileSite));
  }),
  rest.delete(basePath('/challenges/:id'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getChallengesList().results.challenges.filter(
          (game) => game.reference !== '1',
        ),
      ),
    );
  }),
  rest.put(basePath('/challenges/:id'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(basePath('/metrics'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMetrics()));
  }),
  rest.get(basePath('/leaderboards/manager'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getLeaderboard()));
  }),
  rest.get(basePath('/categories'), (req, res, ctx) => {
    const page = req.url.searchParams.get('page')
      ? Number(req.url.searchParams.get('page'))
      : undefined;

    const size = req.url.searchParams.get('size')
      ? Number(req.url.searchParams.get('size'))
      : undefined;

    return res(ctx.status(200), ctx.json(getCategories(page, size)));
  }),
  rest.get(basePath('/units-measure'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getUnitOfMeasure()));
  }),
  rest.get(basePath('/users'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getUsers({
          page: req.url.searchParams.get('page') ?? 0,
          size: req.url.searchParams.get('size') ?? 0,
          query: req.url.searchParams.get('query') ?? '',
          orderBy: req.url.searchParams.get('order_by') ?? 'ASC',
          sortBy: req.url.searchParams.get('sort_by') ?? '',
          roles: req.url.searchParams.getAll('roles') ?? [],
          teams: req.url.searchParams.getAll('teams') ?? [],
          statuses: req.url.searchParams.getAll('statuses') ?? [],
          noTeams:
            Boolean(req.url.searchParams.get('noTeams') === 'true') ?? false,
        } as LoadUsersRequest),
      ),
    );
  }),
  rest.get(basePath('/users/count'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockedUserCounters()));
  }),
  rest.get(basePath('/items'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getStoreItems()));
  }),
  rest.get(basePath('/item-types'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getItemTypes()));
  }),
  rest.get(basePath('/purchases'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPurchases()));
  }),
  rest.get(basePath('/ingestors/files'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getFiles()));
  }),
  rest.get(basePath('/images'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getChallengeImages()));
  }),
  rest.get(basePath('/metrics/category'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getCategorizedMetrics()));
  }),
  rest.get(basePath('/metrics/count'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockedMetricsCounters()));
  }),
  rest.get(
    basePath('/challenge-types/challenge-mode/:reference'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(getChallengeTypes()));
    },
  ),
  rest.get(
    basePath('/challenge-goals/challenge-type/:reference'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(getChallengeGoals()));
    },
  ),
  rest.post(basePath('/challenge-reward/coins'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockChallengePrizeDistributionCoins()),
    );
  }),
  rest.post(basePath('/challenge-reward/points'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockChallengePrizeDistributionPoints()),
    );
  }),
  rest.get(basePath('/leaderboards/challenge/:reference'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockChallengeLeaderboardDuels()));
  }),
  rest.get(basePath('/challenges'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getChallengesList({
          page: req.url.searchParams.get('page') ?? 0,
          size: req.url.searchParams.get('size') ?? 0,
          query: req.url.searchParams.get('query') ?? '',
          orderBy: req.url.searchParams.get('order_by') ?? 'ASC',
          sortBy: req.url.searchParams.get('sort_by') ?? '',
          categories: req.url.searchParams.getAll('categories') ?? [],
          metrics: req.url.searchParams.getAll('metrics') ?? [],
          modes: req.url.searchParams.getAll('modes') ?? [],
          types: req.url.searchParams.getAll('types') ?? [],
          statuses: req.url.searchParams.getAll('statuses') ?? [],
        } as LoadGameRequest),
      ),
    );
  }),
  rest.get(basePath('/challenges/detail/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getChallengeDetails()));
  }),
  rest.post(basePath('/challenges'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(createChallenge()));
  }),
  rest.post(basePath('/challenges/cancel/:reference'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.post(basePath('/challenges/finish/:reference'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(basePath('/challenge-completions/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getChallengeCompletionDetails()));
  }),
  rest.get(basePath('/users/operators'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetAllValidOperators()));
  }),
  rest.post(basePath('/users/ongoing-challenges'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ numberUsersOngoingChallenges: 2 }));
  }),

  rest.post(basePath('/users/live-surveys'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ numberUsersLiveSurveys: 2 }));
  }),

  rest.post(basePath('/charts/metrics'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getChartData()));
  }),
  rest.get(basePath('/teams'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetTeams()));
  }),
  rest.get(basePath('/teams/simplified'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetTeams()));
  }),
  rest.get(basePath('/surveys'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetSurveysData()));
  }),

  rest.get(basePath('/surveys/status'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetSurveysStatus()));
  }),

  rest.get(basePath('/surveys/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetSurveysQuestionData()));
  }),

  rest.get(basePath('/status/:name'), (req, res, ctx) => {
    const component = String(req.params.name);

    return res(ctx.status(200), ctx.json(mockGetStatus(component)));
  }),

  rest.get(basePath('/survey-completions/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getSurveyCompletionDetails()));
  }),

  rest.get(basePath('/categories/simplified'), (req, res, ctx) => {
    const safetyFeature = req.url.searchParams.get('safetyFeature')
      ? req.url.searchParams.get('safetyFeature')
      : undefined;
    const categoryType = req.url.searchParams.get('categoryType')
      ? req.url.searchParams.get('categoryType')
      : undefined;

    return res(
      ctx.status(200),
      ctx.json(getMockedCategories(safetyFeature, categoryType)),
    );
  }),

  rest.get(basePath('/recurrency-config/claim'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockRecurrencyConfigs()));
  }),

  rest.get(
    basePath('/challenge-rewards/recurrent-points-multiplier'),
    (_, res, ctx) => {
      return res(ctx.status(200), ctx.json({ recurrentPointsMultiplier: 5 }));
    },
  ),

  rest.get(basePath('/kudos/users/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ results: mockKudoTypes() }));
  }),

  rest.get(basePath('/features'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getFeatureToggles()));
  }),
  rest.post(basePath('/sites'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getSites()));
  }),

  rest.get(basePath('/sites'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getSites()));
  }),

  rest.get(basePath('/sites/simplified'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getSitesSimplified()));
  }),

  rest.patch(basePath('/sites/:siteReference'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath('/challenges/chart'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetChallengesData()));
  }),

  rest.patch(basePath('/sites/:reference'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getSites().results.sites.filter((site) => site.reference !== '1'),
      ),
    );
  }),

  rest.get(basePath('/languages/codes'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockLanguages()));
  }),

  rest.post(basePath(`/users/language/${'teste'}`), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath(`/sites/challenge/:reference`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockedChallengeSites()));
  }),

  rest.get(basePath(`/challenges/participants/:reference`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockChallengeParticipants()));
  }),

  rest.get(basePath(`/challenge/chart/kpi/:reference`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetChallengeKpis()));
  }),

  rest.get(basePath(`/challenges/chart/:reference`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getMockedMetricChart()));
  }),

  rest.get(basePath(`/role-levels/simplified`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockRoleLevelsSimplified()));
  }),

  rest.get(basePath(`/profiles/role-levels-permissions`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockRoleLevels()));
  }),

  rest.get(basePath('/users/managers'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getManagers({
          page: req.url.searchParams.get('page') ?? 0,
          size: req.url.searchParams.get('size') ?? 0,
          query: req.url.searchParams.get('query') ?? '',
          orderBy: req.url.searchParams.get('order_by') ?? 'ASC',
          sortBy: req.url.searchParams.get('sort_by') ?? '',
          statuses: req.url.searchParams.getAll('statuses') ?? [],
        } as LoadManagersRequest),
      ),
    );
  }),

  rest.patch(basePath(`/users/role-levels`), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(basePath(`/management/public-displays/displays`), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetPublicDisplays()));
  }),
  rest.post(basePath('/public-display/'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(createPublicDisplay()));
  }),
];

export default handlers;

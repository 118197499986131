import kudosService from '@/shared/services/kudosService/kudosService';
import { KudoType } from '@/shared/types/kudos';
import { KudosStoreState } from '@/shared/types/states';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';

export default defineStore('kudos', () => {
  const state = reactive<KudosStoreState>({
    currentUserKudoTypes: [],
  });

  const currentUserTotalKudos = computed<number>(() =>
    state.currentUserKudoTypes.reduce(
      (acc: number, kudoType: KudoType) => acc + kudoType.numberOfReceived,
      0,
    ),
  );

  const getKudoTypesByUserReference = async (
    reference: string,
  ): Promise<void> => {
    try {
      state.currentUserKudoTypes = (
        await kudosService.getKudoTypesByUserReference(reference)
      ).results;
    } catch (_) {
      state.currentUserKudoTypes = [];
    }
  };

  const $reset = (): void => {
    state.currentUserKudoTypes = [];
  };

  return { state, getKudoTypesByUserReference, currentUserTotalKudos, $reset };
});

import { PositionChallengeDistribution } from '@/shared/types/challenges';

export default (): PositionChallengeDistribution => ({
  participation: 0.8,
  firstPlace: 4,
  secondPlace: 8,
  thirdPlace: 10,
  totalPlayers: 30,
  total: 50,
});

import { Feature, NavigationLink, Routes } from '@/shared/types/generic';
import { FTXDriveStep } from '@/shared/types/ftx';
import { shallowRef } from 'vue';
import { PermissionCode } from '@/shared/types/roles';
import navigationItems from './navigationItems';
import ftxSections from './ftxSections';

const navigationLinks = shallowRef<NavigationLink[]>([]);
const driverSteps = shallowRef<FTXDriveStep[]>([]);

export default function useNavMenuData() {
  function updateNavMenuData(
    isFeatureEnabled: (feature: Feature) => boolean,
    isPermissionActive: (permission: PermissionCode) => boolean,
  ): void {
    navigationLinks.value = navigationItems.filter((item: NavigationLink) => {
      if (item.permissions === undefined) {
        return true;
      }

      return item.permissions.some((permission) =>
        isPermissionActive(permission),
      );
    });

    driverSteps.value = ftxSections.filter((section: FTXDriveStep) => {
      let isFeatureActive = true;

      if (section.feature) {
        isFeatureActive = isFeatureEnabled(section.feature);
      }

      if (isFeatureActive && section.permissions) {
        isFeatureActive = section.permissions.some((permission) =>
          isPermissionActive(permission),
        );
      }

      return isFeatureActive;
    });
  }

  function getNextAvailableNavLink(): string {
    return navigationLinks.value.length
      ? navigationLinks.value[0].to
      : Routes.ACCOUNT_SETTINGS;
  }

  function $reset(): void {
    navigationLinks.value = [];
    driverSteps.value = [];
  }

  return {
    navigationLinks,
    driverSteps,
    updateNavMenuData,
    getNextAvailableNavLink,
    $reset,
  };
}

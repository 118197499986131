const mockTest = [
  { name: 'unitMeasure.cubes', reference: 'cubes' },
  { name: 'unitMeasure.quantity', reference: 'reference 2' },
];

const getUnitOfMeasure = () => ({
  metadata: {
    totalItems: mockTest.length,
    lastUpdated: 1595030400000,
  },
  results: {
    unitsMeasure: mockTest,
  },
});

export default getUnitOfMeasure;

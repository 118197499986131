import { defaultItemIllustration } from '@/shared/constants/icons';
import {
  Image,
  ImageTypeStaticReference,
  ResponseMetadata,
} from '@/shared/types/generic';
import {
  PrizeAudienceType,
  PurchaseItem,
  PurchaseStatus,
  StoreCategory,
  StoreItem,
  StoreStatus,
  StoreType,
} from '@/shared/types/store';
import { users } from '../user/mockUser';

export const createStoreItems = (
  amount: number,
  type: StoreType,
): StoreItem[] =>
  new Array(amount).fill(0).map((_value, index) => ({
    id: index,
    reference: `item-${index * 2}`,
    name: `joana name ${index}`,
    description: 'demo description',
    imageUrl: defaultItemIllustration,
    imageName: 'Item_Illustration_default.png',
    itemOrigin: type,
    category: { id: 1, name: 'item.type.avatar', reference: 'item-type-1' },
    visibilityOutStock: false,
    publishStartDate: 1701209475000,
    publishEndDate: 1701209475000,
    price: 9999,
    realPrice: 10.0,
    currencySymbol: '€',
    prependSymbol: false,
    stockNumber: 3333,
    createdAt: 1669195546000,
    updatedAt: 1669715911000,
    modifiedBy: 'Manager Benfica',
    tag: 'sports',
    status: StoreStatus.SCHEDULED,
    audience: {
      type: PrizeAudienceType.ORG,
    },
  }));

export const createPurchaseItems = (amount: number): PurchaseItem[] =>
  new Array(amount).fill(0).map((_value, index) => ({
    itemImageUrl: defaultItemIllustration,
    name: 'CallingDune',
    realPrice: 22.5,
    currencySymbol: '€',
    prependSymbol: false,
    coins: 1000,
    stock: index + 1,
    userImageUrl: defaultItemIllustration,
    username: users[0].name,
    purchaseReference: '12',
    itemReference: `item-${index * 2}`,
    purchaseDate: 1669231269055,
    purchaseStatus:
      index % 2 === 0 ? PurchaseStatus.ACQUIRED : PurchaseStatus.REFUNDED,
    itemOrigin: index % 2 === 0 ? 'collectable' : 'prize',
    category: index % 2 === 0 ? 'item.type.avatar' : 'custom',
    refundUsername: 'manager',
    refundUserImageUrl: defaultItemIllustration,
    refundDate: 1669231269056,
    refundComment: 'comment',
    userReference: users[0].reference,
    refundUserReference: 'manager-ref',
  }));

export const items = [
  ...createStoreItems(10, StoreType.Prize),
  ...createStoreItems(10, StoreType.Collectable),
];

export const metadata: ResponseMetadata = {
  totalItems: items.length,
  lastUpdated: 1595030400000,
};

export const getStoreItems = () => ({
  metadata,
  results: {
    items,
  },
});

const createItemTypes = (amount: number): StoreCategory[] =>
  new Array(amount).fill(0).map((_value, index) => ({
    id: index,
    name: `item.type.avatar`,
    reference: `item-type-${index}`,
  }));

export const itemTypes = [...createItemTypes(4)];

export const metadataCategories: ResponseMetadata = {
  totalItems: itemTypes.length,
  lastUpdated: 1595030400000,
};

export const getItemTypes = () => ({
  metadata: { ...metadataCategories },
  results: {
    itemTypes,
  },
});

const createImages = (amount: number): Image[] =>
  new Array(amount).fill(0).map((_value, index) => ({
    default: index === 0,
    description: 'description',
    imageType: ImageTypeStaticReference.CUSTOM,
    url: defaultItemIllustration,
    name: 'Item_Illustration_default.png',
    organizationId: 1,
    reference: '51562ba1-1591-3d41-b579-e902e7b850c6',
  }));

export const images = [...createImages(4)];

export const metadataImages: ResponseMetadata = {
  totalItems: images.length,
  lastUpdated: 1595030400000,
};

export const getImages = () => ({
  metadata: { ...metadataImages },
  results: {
    images,
  },
});

const purchaseItems = [...createPurchaseItems(10)];

export const getPurchases = () => ({
  metadata: { ...metadataCategories },
  results: purchaseItems,
});

import { DurationPreset, ResponseMetadata } from '@/shared/types/generic';
import {
  Survey,
  SurveysResponseStatus,
  SurveyData,
  SurveyQuestionsResults,
  SurveyQuestion,
  NewSurvey,
  NewSurveyRequest,
  EditSurveyRequest,
  SurveyStatus,
} from '@/shared/types/surveys';

export const metadata: ResponseMetadata = {
  totalItems: 8,
  lastUpdated: 1595030400000,
};

export const exampleSurveyQuestions: SurveyQuestion[] = [
  {
    order: 1,
    question: 'surveys.questions.one',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
  {
    order: 2,
    question: 'surveys.questions.two',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
  {
    order: 3,
    question: 'surveys.questions.three',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
];

export const exampleCustomSurveyQuestions: SurveyQuestion[] = [
  {
    order: 4,
    question: 'question 1',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
  {
    order: 5,
    question: 'question 2',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
  {
    order: 6,
    question: 'question 3',
    reference: 'reference',
    error: '',
    active: true,
    surveyAnswer: {
      1: 2,
      2: 1,
      3: 0,
      4: 3,
      5: 3,
    },
  },
];

export const mockGetDefaultQuestions = () => {
  return {
    metadata,
    results: exampleSurveyQuestions,
  };
};

export const surveyResponses: Survey[] = [
  {
    title: 'Survey 1',
    reference: '1',
    surveyId: '0001',
    recipients: 100,
    createdAt: 1682636400000,
    status: SurveyStatus.SCHEDULED,
    startTime: 1682635400000,
    endTime: 1682636400000,
    completions: 1,
    description: 'desc',
    defaultQuestions: [
      {
        order: 1,
        question: 'surveys.questions.one',
      },
    ],
    customQuestions: [
      {
        order: 2,
        question: 'custom question',
      },
    ],
    numberOfRespondents: 1,
    hasMultipleTimezones: true,
    realEndTime: 1682635400000,
    realStartTime: 1682635400000,
    forcedCompleted: false,
  },
  {
    title: 'Survey 2',
    reference: '2',
    surveyId: '0002',
    recipients: 200,
    createdAt: 1682636400000,
    status: SurveyStatus.LIVE,
    startTime: 1682636400000,
    endTime: 1682636400000,
    completions: 50,
    description: 'desc',
    defaultQuestions: [
      {
        order: 1,
        question: 'question',
      },
    ],
    customQuestions: [],
    numberOfRespondents: 1,
    hasMultipleTimezones: false,
    realEndTime: 1682635400000,
    realStartTime: 1682635400000,
    forcedCompleted: false,
  },
  {
    title: 'Survey 3',
    reference: '3',
    surveyId: '0003',
    recipients: 100,
    createdAt: 1682636400000,
    status: SurveyStatus.COMPLETED,
    startTime: 1682636400000,
    endTime: 1682636480000,
    completions: 98,
    description: 'desc',
    defaultQuestions: [
      {
        order: 1,
        question: 'question',
      },
    ],
    customQuestions: [],
    numberOfRespondents: 1,
    hasMultipleTimezones: false,
    realEndTime: 1682635400000,
    realStartTime: 1682635400000,
    forcedCompleted: false,
  },
  {
    title: 'Survey 4',
    reference: '4',
    surveyId: '0004',
    recipients: 100,
    createdAt: 1682636400000,
    status: SurveyStatus.COMPLETED,
    startTime: 1682636400000,
    endTime: 1682636480000,
    completions: 98,
    description: 'desc',
    defaultQuestions: [
      {
        order: 1,
        question: 'question',
      },
    ],
    customQuestions: [],
    numberOfRespondents: 1,
    hasMultipleTimezones: false,
    realEndTime: 1682635400000,
    realStartTime: 1682635400000,
    forcedCompleted: true,
  },
];

export const surveysStatus: SurveysResponseStatus[] = [
  {
    reference: 'ce534ffd-7ab4-8771-28be-439c4fd3f53e',
    name: 'SCHEDULED',
    translationKey: SurveyStatus.SCHEDULED,
  },
  {
    reference: 'a2896e82-cf26-ab24-27d6-47e5fc0a253d',
    name: 'COMPLETED',
    translationKey: SurveyStatus.COMPLETED,
  },
  {
    reference: '0e2b0f3b-69a9-908e-ab90-a409972b1b80',
    name: 'LIVE',
    translationKey: SurveyStatus.LIVE,
  },
];

export const surveysQuestionData: SurveyData = {
  numberOfRespondents: 15,
  surveyDefaults: [
    {
      reference: 'b79c2f76-694b-e894-6253-9760e65d553c',
      question: 'surveys.questions.one',
      order: 1,
      surveyAnswer: {
        1: 3,
        2: 2,
        3: 4,
        4: 1,
        5: 5,
      },
    },
    {
      reference: 'ce73bc4b-6168-15a8-4868-bfc54df2ff2a',
      question: 'surveys.questions.two',
      order: 2,
    },
    {
      reference: 'a8510f70-bbf8-94d8-cec6-f0328a642cc0',
      question: 'surveys.questions.three',
      order: 3,
    },
    {
      reference: '4e58c3cd-daf3-9f47-2fdc-fa0210122bf7',
      question: 'surveys.questions.four',
      order: 4,
    },
    {
      reference: '26a26f53-791c-9b48-a2e1-0577ca46b119',
      question: 'surveys.questions.five',
      order: 5,
    },
  ],
  surveyCustoms: [
    {
      reference: 'd50128d5-f603-e164-c55a-a00ef6ad1a90',
      question: 'This is first dummy question',
      order: 6,
      surveyAnswer: {
        1: 2,
        2: 1,
        3: 0,
        4: 3,
        5: 3,
      },
    },
    {
      reference: 'db15214b-d30a-efc2-5b84-a2eb137cf271',
      question: 'This a second dummy question',
      order: 7,
    },
    {
      reference: '88c7edcf-02a1-406e-abbb-2320ad862895',
      question: 'This a third dummy question',
      order: 8,
    },
  ],
};

export const mockGetSurveysData = () => {
  return {
    metadata,
    results: {
      surveyResponses,
    },
  };
};

export const mockGetSurveysQuestionData = () => {
  return {
    surveyQuestions: surveysQuestionData,
  };
};

export const mockGetSurveysStatus = () => {
  return {
    metadata,
    results: surveysStatus,
  };
};

export const mockSurveyQuestionsResults: SurveyQuestionsResults[] = [
  {
    question: 'surveys.questions.one',
    order: 1,
    surveyAnswer: {
      1: 2,
      2: 5,
      3: 5,
      4: 1,
      5: 3,
    },
  },
  {
    question: 'surveys.questions.two',
    order: 2,
    surveyAnswer: {
      1: 2,
      2: 5,
      3: 5,
      4: 1,
      5: 3,
    },
  },
  {
    question: 'surveys.questions.three',
    order: 3,
    surveyAnswer: {
      1: 2,
      2: 5,
      3: 5,
      4: 1,
      5: 3,
    },
  },
  {
    question: 'surveys.questions.four',
    order: 4,
    surveyAnswer: {
      1: 2,
      2: 5,
      3: 5,
      4: 1,
      5: 3,
    },
  },
  {
    question: 'surveys.questions.five',
    order: 5,
    surveyAnswer: {
      1: 2,
      2: 5,
      3: 5,
      4: 1,
      5: 3,
    },
  },
];

export const exampleNewSurvey: NewSurvey = {
  title: 'title',
  description: 'description',
  audienceNumber: 10,
  preset: DurationPreset.DAY,
  startDate: new Date(1682636400000),
  endDate: new Date(1682636480000),
  defaultQuestions: [
    {
      order: 1,
      question: 'surveys.questions.one',
    },
  ],
  customQuestions: [
    {
      order: 2,
      question: 'custom question',
    },
  ],
  realEndTime: 1682636400000,
  realStartTime: 1682636480000,
  hasMultipleTimezones: false,
};

export const exampleNewRequestSurvey: NewSurveyRequest = {
  title: 'title',
  description: 'description',
  startTime: 1682636400000,
  endTime: 1682636480000,
  defaultQuestions: [
    {
      order: 1,
      question: 'surveys.questions.one',
    },
  ],
  customQuestions: [
    {
      order: 2,
      question: 'custom question',
    },
  ],
};

export const mockGetSurveyAudience = () => {
  return {
    numberOfUsers: 20,
  };
};

export const exampleEditRequestSurvey: EditSurveyRequest = {
  ...exampleNewRequestSurvey,
  reference: '1',
};

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.87159 0.879962C8.25334 -0.176156 9.74697 -0.176127 10.1287 0.88001L12.4787 7.38209L16.0357 4.68653C16.9436 3.99851 18.2094 4.84414 17.9215 5.94626L15.1629 16.5051C14.9329 17.3854 14.1377 17.9996 13.2278 17.9996H4.77244C3.86258 17.9996 3.06738 17.3854 2.83739 16.5051L0.0787978 5.94629C-0.20915 4.84414 1.05675 3.99853 1.96464 4.68659L5.52129 7.38207L7.87159 0.879962ZM9.00008 3.64138L7.40218 8.06195C6.94385 9.32992 5.38781 9.79039 4.31327 8.97603L2.59781 7.67593L4.77244 15.9996L13.2278 15.9996L15.4025 7.67576L13.6866 8.97609C12.612 9.79041 11.056 9.32988 10.5977 8.06189L9.00008 3.64138Z"
      :fill="props.color"
    />
  </svg>
</template>

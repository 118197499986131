<script setup lang="ts">
import { BLUE_500 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: BLUE_500,
  colorSecondary: '#E3EEFF',
});
</script>
<template>
  <svg
    width="261"
    height="260"
    viewBox="0 0 261 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.641 255.906C200.083 255.906 256.386 199.54 256.386 130C256.386 96.8648 243.607 66.7277 222.709 44.2443C199.745 19.538 166.997 4.09449 130.641 4.09449C61.1983 4.09449 4.89551 60.4595 4.89551 130C4.89551 199.54 61.1983 255.906 130.641 255.906ZM130.641 260C202.349 260 260.48 201.797 260.48 130C260.48 95.791 247.283 64.6681 225.708 41.4567C202.002 15.9526 168.182 0 130.641 0C58.9322 0 0.801025 58.203 0.801025 130C0.801025 201.797 58.9322 260 130.641 260Z"
      :fill="props.colorSecondary"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.641 4.09449C61.1049 4.09449 4.73499 60.464 4.73499 130C4.73499 131.13 3.81841 132.047 2.68775 132.047C1.55709 132.047 0.640503 131.13 0.640503 130C0.640503 58.2026 58.8435 0 130.641 0C131.771 0 132.688 0.916216 132.688 2.04688C132.688 3.17754 131.771 4.09449 130.641 4.09449ZM258.593 127.952C259.724 127.952 260.641 128.869 260.641 130C260.641 201.797 202.438 260 130.641 260C129.51 260 128.593 259.083 128.593 257.952C128.593 256.822 129.51 255.906 130.641 255.906C200.176 255.906 256.546 199.535 256.546 130C256.546 128.869 257.463 127.952 258.593 127.952Z"
      :fill="props.color"
    />
  </svg>
</template>

import { TableRow } from './components';
import { Team } from './user';

export enum StoreType {
  Collectable = 'collectable',
  Prize = 'prize',
  PurchaseActivity = 'purchaseActivity',
  ALL = 'all',
}

export enum StoreStatus {
  ALL = 'all',
  AVAILABLE = 'available',
  SCHEDULED = 'scheduled',
  HIDDEN = 'hidden',
}

export enum PurchaseStatus {
  ACQUIRED = 'acquired',
  REFUNDED = 'refunded',
}

export interface StoreCategory {
  name: string;
  reference: string;
}

export enum PrizeAudienceType {
  ORG = 'ORG',
  SITE = 'SITE',
  TEAMS = 'TEAMS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type StoreItemTeam = Pick<Team, 'reference' | 'name' | 'numberOfUsers'>;

export interface StoreItem {
  reference: string;
  name: string;
  description: string;
  imageUrl: string;
  imageName: string;
  itemOrigin: string;
  category: StoreCategory;
  visibilityOutStock: boolean;
  publishStartDate: number;
  publishEndDate: number;
  prependSymbol: boolean;
  tag: string;
  price: number;
  realPrice: number;
  currencySymbol: string;
  stockNumber: number;
  createdAt: number;
  updatedAt: number;
  modifiedBy: string;
  status: StoreStatus;
  audience: {
    type: PrizeAudienceType;
    teams?: StoreItemTeam[];
  };
}

export interface PurchaseItem {
  itemImageUrl: string;
  name: string;
  realPrice: number;
  currencySymbol: string;
  prependSymbol: boolean;
  coins: number;
  stock: number | null;
  userImageUrl: string;
  username: string;
  userReference: string;
  purchaseReference: string;
  itemReference: string;
  purchaseDate: number;
  purchaseStatus: PurchaseStatus;
  refundUsername?: string;
  refundUserImageUrl?: string;
  refundDate?: number;
  refundComment?: string;
  refundUserReference: string;
  itemOrigin: string;
  category: string;
}

export interface StoreCategoryItemList {
  name: string;
  reference: string;
  list: TableRow<StoreItem>[];
  filteredLength?: number;
}

export interface NewCategory {
  name: string;
}

export interface NewCategoryErrors {
  name: string;
}

export interface EditCategory {
  name: string;
}

export interface EditCategoryErrors {
  name: string;
}

export interface DeleteCategory {
  consent: boolean;
}

export interface DeleteCategoryErrors {
  consent: string;
}

export enum Availability {
  IMMEDIATELY = 'immediately',
  HIDDEN = 'hidden',
  SCHEDULED = 'scheduled',
}

export interface AvailabilityOption {
  id: Availability;
  label: string;
}

export interface NewItemErrors {
  file: string;
  name: string;
  description: string;
  itemTypeReference: string;
  imageName: string;
  imageSize: string;
  price: string;
  stockNumber: string;
  unlimitedStock: string;
  visibilityOutStock: string;
  availability: string;
  startDate: string;
  endDate: string;
}

export interface StoreSidebarEditErrors {
  name: string;
  description: string;
  itemTypeReference: string;
  imageName: string;
  price: string;
  stockNumber: string;
  unlimitedStock: string;
  visibilityOutStock: string;
  availability: string;
  startDate: string;
  endDate: string;
}

export interface EditItemErrors {
  file: string;
  name: string;
  description: string;
  itemTypeReference: string;
  imageName: string;
  imageSize: string;
  price: string;
  stockNumber: string;
  unlimitedStock: string;
  visibilityOutStock: string;
  availability: string;
  startDate: string;
  endDate: string;
}

export interface StoreItemForm {
  name: string;
  imageName: string;
  description: string;
  unlimitedStock: boolean;
  visibilityOutStock: boolean;
  file?: File;
  price?: number;
  endDate?: Date;
  startDate?: Date;
  stockNumber?: number;
  itemType?: StoreCategory;
  availability?: AvailabilityOption;
  teams: StoreItemTeam[];
}

export interface StoreItemPayload {
  name: string;
  imageName: string;
  description: string;
  visibilityOutStock: boolean;
  audienceType: PrizeAudienceType;
  file?: File;
  price?: number;
  endDate?: number;
  startDate?: number;
  stockNumber?: number;
  itemTypeReference?: string;
  audienceReferences?: string;
}

import { defineStore } from 'pinia';
import { LeaderboardUser } from '@/shared/types/leaderboard';
import leaderboardService from '@/shared/services/leaderboardService/leaderboardService';

interface State {
  leaderboard: LeaderboardUser[];
}

export default defineStore({
  id: 'leaderboard',

  state: (): State => ({
    leaderboard: [],
  }),

  actions: {
    async loadLeaderboard() {
      try {
        this.leaderboard = (
          await leaderboardService.getLeaderboard()
        ).results.leaderboard;
      } catch (error) {
        this.leaderboard = [];
      }
    },
  },
});

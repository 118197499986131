import { setupWorker, rest } from 'msw';
import handlers from './handlers';

// This configures a Service Worker with the given request handlers.
const worker = setupWorker(...handlers);

// Make the `worker` and `rest` references available globally,
// so they can be accessed in both runtime and test suites.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).msw = {
  worker,
  rest,
};

export default worker;

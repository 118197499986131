import { defaultItemIllustration } from '@/shared/constants/icons';
import {
  ChallengeAudience,
  ChallengeAudienceStaticReference,
} from '@/shared/types/challenges';
import { ResponseMetadata } from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const mockChallengeAudience = (
  staticReference = ChallengeAudienceStaticReference.INDIVIDUAL,
): ChallengeAudience => ({
  reference: staticReference,
  name: `${staticReference}.name`,
  description: `${staticReference}.description`,
  imageUrl: defaultItemIllustration,
  staticReference,
});

import confetti from 'canvas-confetti';
import useFeatures from '@/composables/useFeatures/useFeatures';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { Feature } from '@/shared/types/generic';
import {
  DriveElements,
  FTXDriveStep,
  JoyrideButtonsLabel,
} from '@/shared/types/ftx';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import postHogUtils from '@/composables/usePostHog/utils';
import { PostHogEvents } from '@/shared/types/posthog';

export default function useDriverJs(
  steps: FTXDriveStep[],
  buttonsLabel: JoyrideButtonsLabel,
) {
  const { isFeatureEnabled } = useFeatures();

  const initialTimeEvent = new Date();

  const findDashboardPosition = steps.findIndex(
    (step) => step.element === `#vaibe-ftx__${DriveElements.HOME}`,
  );
  if (
    !isFeatureEnabled(Feature.DASHBOARD_MANAGER) &&
    findDashboardPosition > 0
  ) {
    steps.splice(findDashboardPosition, 1);
  }

  const findDataImportPosition = steps.findIndex(
    (step) => step.element === `#vaibe-ftx__${DriveElements.DATA_IMPORT}`,
  );
  if (
    !isFeatureEnabled(Feature.METRIC_DATA_IMPORT) &&
    findDataImportPosition > 0
  ) {
    steps.splice(findDataImportPosition, 1);
  }

  const findPublicDisplaysPosition = steps.findIndex(
    (step) => step.element === `#vaibe-ftx__${DriveElements.PUBLIC_DISPLAYS}`,
  );
  if (
    !isFeatureEnabled(Feature.PUBLIC_DISPLAY_MANAGER) &&
    findPublicDisplaysPosition > 0
  ) {
    steps.splice(findPublicDisplaysPosition, 1);
  }

  const findSurveysPosition = steps.findIndex(
    (step) => step.element === `#vaibe-ftx__${DriveElements.SURVEYS}`,
  );
  if (
    !isFeatureEnabled(Feature.SURVEY_DEFAULT_QUESTIONS) &&
    findSurveysPosition > 0
  ) {
    steps.splice(findSurveysPosition, 1);
  }

  const lastPositionValue: FTXDriveStep = steps[steps.length - 1];

  const newSteps = steps.map((step: FTXDriveStep, index: number) => {
    const newStep = {
      element: step.element,
      popover: {
        ...step.popover,
        progressText: `${index}/${steps.length - 1}`,
      },
    } as FTXDriveStep;
    return newStep;
  });

  const driverConfigs = driver({
    popoverClass: 'vaibe-main__background',
    disableButtons: ['previous'],
    steps: newSteps,
    allowClose: true,
    showProgress: true,
    disableActiveInteraction: true,
    progressText: '{{current}}/{{total}}',
    nextBtnText: buttonsLabel.next,
    prevBtnText: buttonsLabel.previous,
    doneBtnText: buttonsLabel.done,
    onPopoverRender: (popover, { state }) => {
      if (!state.activeStep?.popover?.showProgress) {
        popover.progress.remove();
      }
    },
    async onDestroyed(_, step: FTXDriveStep) {
      const endTimeEvent = new Date();

      usePostHogEvents().postHogEvent(
        PostHogEvents.FTX_DISMISS_JOYRIDE_MANAGER,
        {
          ftxPage: step.feature as string,
          initialTimeEvent: `${initialTimeEvent.toLocaleDateString()} ${initialTimeEvent.toLocaleTimeString()}`,
          endTimeEvent: `${endTimeEvent.toLocaleDateString()} ${endTimeEvent.toLocaleTimeString()}`,
          durationInSeconds: postHogUtils.calculateDurationEventTimeInSeconds(
            initialTimeEvent,
            endTimeEvent,
          ),
        },
      );

      if (step.element === lastPositionValue.element) {
        try {
          await confetti({
            particleCount: 400,
            startVelocity: 20,
            spread: 360,
            origin: { y: 0.2, x: 0.9 },
          });
        } finally {
          confetti.reset();
        }
      }
    },
  });

  driverConfigs.drive();
}

export interface PinInformation {
  pin: string;
  orgName: string;
  ingress: string;
  activePin: boolean;
}

export enum AudienceType {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export interface PublicDisplay {
  name: string;
  description: string;
  displayCode: string;
  activePin: boolean;
  language: string;
  layoutType: string;
  link: string;
  pin: string;
  audiences: string[];
  audienceType?: AudienceType;
  challenges: {
    enabled: boolean;
  };
  metrics: {
    enabled: boolean;
    references: string[];
  };
}

export interface NewPublicDisplay {
  audienceType: AudienceType;
  audienceReferences: string[];
  isPinProtected: boolean;
  name: string;
  description?: string;
  language: string;
}

export interface PublicDisplaysWidgets {
  challenges: {
    enabled: boolean;
  };
  metrics: {
    enabled: boolean;
    references?: string[];
  };
}

export enum ShareType {
  GENERIC = 'generic',
  SPECIFIC = 'specific',
}

export interface PublicDisplaysResponse {
  link: string;
  items: PublicDisplay[];
}

export interface LanguagesList {
  label: string;
  code: string;
}

export interface PublicDisplaysRequest extends NewPublicDisplay {
  pin: string;
}

export enum PublicDisplaysWidgetsType {
  CHALLENGES = 'challenges',
  METRICS = 'metrics',
}

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 2L8 8H2V3C2 2.44772 2.44772 2 3 2H8ZM15 2C15.5523 2 16 2.44772 16 3V8H10L10 2H15ZM3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3ZM16 10V15C16 15.5523 15.5523 16 15 16H10L10 10H16ZM8 16H3C2.44772 16 2 15.5523 2 15V10H8L8 16Z"
      :fill="props.color"
    />
  </svg>
</template>

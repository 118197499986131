import rolesService from '@/shared/services/rolesService/rolesService';
import { ApiResponse } from '@/shared/types/generic';
import { PaginationParameters } from '@/shared/types/requests';
import {
  AddCustomRoleLevelRequest,
  EditCustomRoleLevelRequest,
  OrgRoleLevel,
  PermissionCode,
  ProfileRoleLevel,
  RoleLevelsSimplified,
} from '@/shared/types/roles';
import { IManager } from '@/shared/types/user';
import {
  PermissionType,
  RoleTypeStaticReference,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export interface RoleStoreState {
  roleLevelsSimplified: RoleLevelsSimplified;
  profileRoleLevel: ProfileRoleLevel;
  orgRoleLevels: OrgRoleLevel[];
  counters: {
    numberOfRoleLevels: number;
  };

  loadOrgRoleLevelRequest: PaginationParameters;
  paginatedRoleLevels: OrgRoleLevel[];
}

export default defineStore('roles', () => {
  const state = reactive<RoleStoreState>({
    profileRoleLevel: {
      name: '',
      reference: '',
      activePermissionsCodes: {} as Record<PermissionCode, boolean>,
    },

    roleLevelsSimplified: {
      defaultRoleLevels: [],
      customRoleLevels: [],
    },

    orgRoleLevels: [],

    counters: {
      numberOfRoleLevels: 0,
    },

    loadOrgRoleLevelRequest: {} as PaginationParameters,

    paginatedRoleLevels: [],
  });

  function isPermissionActive(permissionCode: PermissionCode): boolean {
    return state.profileRoleLevel.activePermissionsCodes[permissionCode];
  }

  async function loadProfileRoleLevel(): Promise<void> {
    const { name, reference, activePermissionsCodes } =
      await rolesService.getProfileRoleLevels();

    state.profileRoleLevel.name = name;
    state.profileRoleLevel.reference = reference;
    state.profileRoleLevel.activePermissionsCodes = {} as Record<
      PermissionCode,
      boolean
    >;
    activePermissionsCodes.forEach((permissionCode: PermissionCode) => {
      state.profileRoleLevel.activePermissionsCodes[permissionCode] = true;
    });
  }

  async function loadRoleLevelsSimplified(
    organizationName?: string,
  ): Promise<void> {
    const { defaultRoleLevels, customRoleLevels } =
      await rolesService.getRoleLevelsSimplified(
        RoleTypeStaticReference.MANAGER,
        organizationName,
      );
    state.roleLevelsSimplified.defaultRoleLevels = defaultRoleLevels;
    state.roleLevelsSimplified.customRoleLevels = customRoleLevels;
  }

  async function getOrgRoleLevels(
    paginationRequest: PaginationParameters = {},
  ): Promise<OrgRoleLevel[]> {
    return (await rolesService.getOrgRoleLevels(paginationRequest)).results
      .rolesPermissions;
  }

  async function loadCounters(): Promise<void> {
    const { numberOfRoleLevels } = await rolesService.getCounters();
    state.counters.numberOfRoleLevels = numberOfRoleLevels;
  }

  async function getRoleLevelPermissions(
    roleLevelReference?: string,
  ): Promise<{ permissionTypes: PermissionType[] }> {
    return rolesService.getRoleLevelPermissions(roleLevelReference);
  }

  async function createCustomRoleLevel(
    request: AddCustomRoleLevelRequest,
  ): Promise<void> {
    return rolesService.createCustomRoleLevel(request);
  }

  async function deleteCustomRoleLevel(
    roleLevelReference: string,
  ): Promise<void> {
    return rolesService.deleteCustomRoleLevel(roleLevelReference);
  }

  async function editCustomRoleLevel(
    request: EditCustomRoleLevelRequest,
  ): Promise<void> {
    return rolesService.editCustomRoleLevel(request);
  }

  async function getManagersToAssignRoleLevel(
    roleLevelReference?: string,
  ): Promise<ApiResponse<IManager[]>> {
    return rolesService.getManagersToAssignRoleLevel(roleLevelReference);
  }

  return {
    state,
    loadRoleLevelsSimplified,
    loadProfileRoleLevel,
    isPermissionActive,
    getOrgRoleLevels,
    loadCounters,
    getRoleLevelPermissions,
    createCustomRoleLevel,
    deleteCustomRoleLevel,
    editCustomRoleLevel,
    getManagersToAssignRoleLevel,
  };
});

import { defineStore } from 'pinia';
import { Status, StatusType } from '@/shared/types/generic';
import statusService from '@/shared/services/statusService/statusService';

export default defineStore({
  id: 'status',

  state: () => ({
    status: {} as Record<StatusType, Status[]>,
  }),

  actions: {
    async loadViewStatus(statusType: StatusType): Promise<void> {
      try {
        const statusList = await statusService.getStatusList(statusType);

        this.status[statusType] = statusList.results;
      } catch (error) {
        this.status[statusType] = [];
      }
    },
  },
});

import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import {
  EditCategory,
  NewCategory,
  StoreItemPayload,
  PurchaseItem,
  StoreCategory,
  StoreItem,
} from '@/shared/types/store';

export default {
  getCategories(): Promise<ApiResponse<{ itemTypes: StoreCategory[] }>> {
    return axios.get('/item-types');
  },

  getPurchases(): Promise<ApiResponse<PurchaseItem[]>> {
    return axios.get('/purchases');
  },

  createCategory(newCategory: NewCategory): Promise<void> {
    return axios.post('/item-types', newCategory);
  },

  refundPurchase(reference: string, comment: string): Promise<void> {
    return axios.post(`purchases/${reference}/refund`, { comment });
  },

  editCategory(
    category: EditCategory,
    categoryReference: string,
  ): Promise<void> {
    return axios.put(`/item-types/${categoryReference}`, category);
  },

  deleteCategory(categoryReference: string): Promise<void> {
    return axios.delete(`/item-types/${categoryReference}`);
  },

  getStoreItems(): Promise<ApiResponse<{ items: StoreItem[] }>> {
    return axios.get('/items');
  },

  createStoreItem(newItem: StoreItemPayload): Promise<void> {
    return axios.post(`/items/custom`, newItem, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  editStoreItem(
    updatedItem: StoreItemPayload,
    itemReference: string,
  ): Promise<void> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios.put(`items/${itemReference}`, updatedItem, config);
  },

  deleteStoreItem(itemReference: string): Promise<void> {
    return axios.delete(`/items/custom/${itemReference}`);
  },
};

import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import { KudoType } from '@/shared/types/kudos';

export default {
  getKudoTypesByUserReference(
    reference: string,
  ): Promise<ApiResponse<KudoType[]>> {
    return axios.get(`/kudos/users/${reference}`);
  },
};

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0001 7C15.3808 7 16.5001 5.88071 16.5001 4.5C16.5001 3.11929 15.3808 2 14.0001 2C12.6193 2 11.5001 3.11929 11.5001 4.5C11.5001 5.88071 12.6193 7 14.0001 7ZM14.0001 9C16.4853 9 18.5001 6.98528 18.5001 4.5C18.5001 2.01472 16.4853 0 14.0001 0C11.5148 0 9.50006 2.01472 9.50006 4.5C9.50006 6.98528 11.5148 9 14.0001 9Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0001 9H13.0001C11.3432 9 10.0001 10.3431 10.0001 12V14H18.0001V12C18.0001 10.3431 16.6569 9 15.0001 9ZM13.0001 7C10.2386 7 8.00006 9.23858 8.00006 12V14C8.00006 15.1046 8.89549 16 10.0001 16H18.0001C19.1046 16 20.0001 15.1046 20.0001 14V12C20.0001 9.23858 17.7615 7 15.0001 7H13.0001Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.73638 7C5.56481 7 6.23638 6.32843 6.23638 5.5C6.23638 4.67157 5.56481 4 4.73638 4C3.90796 4 3.23638 4.67157 3.23638 5.5C3.23638 6.32843 3.90796 7 4.73638 7ZM4.73638 9C6.66938 9 8.23639 7.433 8.23639 5.5C8.23639 3.567 6.66938 2 4.73638 2C2.80339 2 1.23638 3.567 1.23638 5.5C1.23638 7.433 2.80339 9 4.73638 9Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 9C2.89543 9 2 9.89543 2 11V13H7V15H1.6C0.716344 15 0 14.2837 0 13.4V11C0 8.79086 1.79086 7 4 7H5V9H4Z"
      :fill="props.color"
    />
  </svg>
</template>

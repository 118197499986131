import {
  File,
  FileErrorWarning,
  LoadFilesRequest,
  NuvoFileValidationRow,
} from '@/shared/types/files';
import { defineStore } from 'pinia';
import filesService from '@/shared/services/filesService/filesService';

const removeNameTimestamp = (file: File): File => {
  return { ...file, name: file.name.slice(file.name.indexOf('_') + 1) };
};

export default defineStore({
  id: 'files',

  state: () => ({
    files: [] as File[],
    validationErrors: [] as FileErrorWarning[],
    validationWarnings: [] as FileErrorWarning[],
    isLoading: false,
    loadFilesRequest: {} as LoadFilesRequest,
    filesCount: {
      numberOfImportedData: 0,
      numberOfImportedDataFiltered: 0,
    },
  }),

  actions: {
    async loadFiles() {
      try {
        this.isLoading = true;
        const response = await filesService.getFiles(this.loadFilesRequest);
        const loadedFiles = response.results.importFiles;
        this.files = loadedFiles.map((file) => removeNameTimestamp(file));
        this.filesCount = {
          numberOfImportedData: response.results.numberOfImportedData,
          numberOfImportedDataFiltered:
            response.results.numberOfImportedDataFiltered,
        };
      } catch (error) {
        this.files = [];
        this.filesCount = {
          numberOfImportedData: 0,
          numberOfImportedDataFiltered: 0,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async validateFile(file: NuvoFileValidationRow[]) {
      this.validationErrors = [];
      this.validationWarnings = [];

      try {
        await filesService.validateFile(file);
        this.validationErrors = [];
        this.validationWarnings = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.validationErrors = error.response.data.errors;
        this.validationWarnings = error.response.data.warnings;
      }
    },

    deleteFile(reference: string) {
      return filesService.deleteFile(reference);
    },

    downloadFile(reference: string) {
      return filesService.downloadFile(reference);
    },

    setLoadFilesRequest(request: LoadFilesRequest): void {
      this.loadFilesRequest = request;
    },
  },
});

export type HTMLEventHandler<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
};

export interface BusinessError {
  code: number;
  message: string;
}

export class ResponseError extends Error {
  public status: number;

  public reason: BusinessError;

  constructor(status: number, data: BusinessError) {
    super((data as BusinessError).message);

    this.status = status;
    this.reason = data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isABusinessError(obj: any): obj is BusinessError {
    return obj && 'code' in obj && 'message' in obj;
  }
}

export type NotificationType = 'info' | 'warning' | 'danger' | 'success';

export interface DropdownItem {
  id: number;
  label: string;
}

export interface SelectItem {
  label: string;
  value: string | number;
}

import { DurationPreset, ProcessCompletionDetails } from './generic';

export enum SurveyStatus {
  SCHEDULED = 'status.survey.scheduled',
  LIVE = 'status.survey.live',
  COMPLETED = 'status.survey.completed',
  ALL = 'all',
}

export interface SurveyScore {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
}

export interface SurveyQuestion {
  order: number;
  question: string;
  reference?: string;
  error?: string;
  active?: boolean;
  surveyAnswer?: SurveyScore;
}

export interface Survey {
  title: string;
  reference: string;
  surveyId: string;
  recipients: number;
  createdAt: number;
  startTime: number;
  endTime: number;
  completions: number;
  status: SurveyStatus;
  description: string;
  defaultQuestions: SurveyQuestion[];
  customQuestions: SurveyQuestion[];
  numberOfRespondents: number;
  realStartTime: number;
  realEndTime: number;
  hasMultipleTimezones: boolean;
  forcedCompleted: boolean;
  completionDetails?: ProcessCompletionDetails;
}

export interface SurveyStatusOption {
  label: string;
  value: string;
}

export interface SurveysResponseStatus {
  reference: string;
  name: string;
  translationKey: string;
}

export enum SurveyWizardStep {
  ID = 1,
  DURATION = 2,
  QUESTIONS = 3,
  SUMMARY = 4,
}

export interface NewSurvey {
  title: string;
  description: string;
  audienceNumber: number;
  preset: DurationPreset;
  startDate: Date;
  endDate: Date;
  defaultQuestions: SurveyQuestion[];
  customQuestions: SurveyQuestion[];
  realStartTime: number;
  realEndTime: number;
  hasMultipleTimezones: boolean;
}

export interface EditSurvey extends NewSurvey {
  reference: string;
}

export interface NewSurveyRequest {
  title: string;
  description: string;
  startTime: number;
  endTime: number;
  defaultQuestions?: SurveyQuestion[];
  customQuestions?: SurveyQuestion[];
  audience?: string[];
  teamSurvey?: boolean;
}

export interface EditSurveyRequest extends NewSurveyRequest {
  reference: string;
}

export interface Reaction {
  value: number;
  score: number;
  type: string;
}

export interface SurveyQuestionsResults {
  question: string;
  order: number;
  surveyAnswer: SurveyScore;
}
export interface SurveyData {
  surveyDefaults: SurveyQuestion[];
  surveyCustoms: SurveyQuestion[];
  numberOfRespondents: number;
}

export interface EditSurveyErrors {
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  defaultQuestions: SurveyQuestion[];
  customQuestions: SurveyQuestion[];
}

import { defaultItemIllustration } from '@/shared/constants/icons';
import { ResponseMetadata } from '@/shared/types/generic';
import { Team } from '@/shared/types/user';

export const metadata: ResponseMetadata = {
  totalItems: 8,
  lastUpdated: 1595030400000,
};

export const team: Array<Team> = [
  {
    name: 'Team 1',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 1',
    reference: 'reference 1',
    orgTeamId: '1',
    numberOfUsers: 10,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 2',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 2',
    reference: 'reference 2',
    orgTeamId: '2',
    numberOfUsers: 15,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 3',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 3',
    reference: 'reference 3',
    orgTeamId: '3',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 4',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 4',
    reference: 'reference 4',
    orgTeamId: '5',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 5',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 5',
    reference: 'reference 5',
    orgTeamId: '6',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 6',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 6',
    reference: 'reference 6',
    orgTeamId: '4',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 7',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 7',
    reference: 'reference 7',
    orgTeamId: '7',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    name: 'Team 8',
    image: {
      name: 'Game Covers 006.png',
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    description: 'description 8',
    reference: 'reference 8',
    orgTeamId: '8',
    numberOfUsers: 20,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
];

export const mockGetTeams = () => {
  return {
    metadata,
    results: {
      team,
    },
  };
};

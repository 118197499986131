/* eslint-disable no-underscore-dangle */
import useTokenStore from '@/store/token/useTokenStore';
import { usePostHogConfig } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export default function usePosHogConfig() {
  const { postHogConfig, postHogInit, postHogLoadToolbar } = usePostHogConfig();
  const tokenStore = useTokenStore();

  function postHogInitialize() {
    postHogInit();
  }

  async function postHogSetup(): Promise<void> {
    await postHogConfig(tokenStore.getPostHogKey);
  }

  async function loadToolbar() {
    postHogLoadToolbar();
  }

  return { postHogSetup, postHogInitialize, loadToolbar };
}

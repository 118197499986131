import axios from '@/plugins/http/axios';
import { ThemesResponse } from '@/shared/types/theme';

export default {
  getThemes(): Promise<ThemesResponse> {
    return axios.get('/organizations/theme');
  },

  getThemesOrg(orgName: string): Promise<ThemesResponse> {
    return axios.get(`/organizations/theme/${orgName}`);
  },
};

import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { NotificationType } from '@/shared/types/utils';
import useSharedStore from '@/store/shared/useSharedStore';

/**
 * Creates a notification snack bar using [Quasar's Notify API]{@link https://quasar.dev/quasar-plugins/notify}.
 * @param message - The content of the message that is displayed inside the snack bar.
 * @param type - The type of snack bar to be displayed. Each type of snack bar has its own styles.
 * @see @/src/quasar-user-options.js for more information on the different notification types
 */
export default function notify(
  message: string,
  type: NotificationType = 'info',
) {
  const sharedStore = useSharedStore();
  const dismiss = Notify.create({ message, type });
  sharedStore.saveNotify(dismiss);
}

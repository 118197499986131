import { defaultItemIllustration } from '@/shared/constants/icons';
import {
  ChallengeType,
  ChallengeTypeStaticReference,
} from '@/shared/types/challenges';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const mockChallengeType = (
  staticReference = ChallengeTypeStaticReference.CLASSIC,
): ChallengeType => ({
  promotes: [
    `challenge.type.promotes.speed`,
    'challenge.type.promotes.consistency',
  ],
  imageUrl: defaultItemIllustration,
  staticReference,
});

export const getChallengeTypes = (): ApiResponse<{
  challengeTypes: ChallengeType[];
}> => ({
  metadata,
  results: {
    challengeTypes: Object.values(ChallengeTypeStaticReference).map(
      mockChallengeType,
    ),
  },
});

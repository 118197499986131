import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import {
  NewPublicDisplay,
  PinInformation,
  PublicDisplaysRequest,
  PublicDisplaysResponse,
  PublicDisplaysWidgets,
} from '@/shared/types/publicDisplays';
import { GetRequestParameters } from '@/shared/types/requests';

export default {
  getPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays');
  },

  editPin(pin: string): Promise<{ message: string }> {
    return axios.post('/management/public-displays/edit/pin', { pin });
  },

  getRandomPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays/random/pin');
  },

  togglePinPublicDisplays(activePin: boolean): Promise<void> {
    return axios.put('/management/public-displays/active-pin', { activePin });
  },

  getPublicDisplaysList({
    sortBy,
    orderBy,
    queryBy,
  }: GetRequestParameters = {}): Promise<ApiResponse<PublicDisplaysResponse>> {
    return axios.get('/management/public-displays/displays', {
      params: {
        sort_by: sortBy,
        order_by: orderBy,
        query_by: queryBy,
      },
    });
  },

  createPublicDisplay(
    publicDisplay: NewPublicDisplay & PublicDisplaysWidgets,
  ): Promise<void> {
    return axios.post('/management/public-displays', publicDisplay);
  },

  editPublicDisplay(
    displayCode: string,
    publicDisplay: PublicDisplaysRequest & PublicDisplaysWidgets,
  ): Promise<void> {
    return axios.put(
      `/management/public-displays/${displayCode}`,
      publicDisplay,
    );
  },

  deletePublicDisplay(publicDisplayCode: string): Promise<void> {
    return axios.delete(`/management/public-displays/${publicDisplayCode}`);
  },
};

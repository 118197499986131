import axios, { AxiosRequestConfig } from 'axios';
import { BusinessError, ResponseError } from '@/shared/types/utils';
import useTokenStore from '@/store/token/useTokenStore';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import useErrorStore from '@/store/error/useErrorStore';
import useSharedStore from '@/store/shared/useSharedStore';

function decodeJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(async (request) => {
  const sharedStore = useSharedStore();
  const tokenStore = useTokenStore();

  const controller = new AbortController();
  sharedStore.setAbortController(controller);

  if (tokenStore.token) {
    try {
      const jwt = decodeJWT(tokenStore.token);
      if (jwt.exp * 1000 < Date.now()) {
        await tokenStore.refreshUserToken();
      }
    } catch (e) {
      tokenStore.$reset();
    }
  }

  const requestConfig: AxiosRequestConfig = {
    ...request,
    signal: controller.signal,
  };

  if (!requestConfig.headers) {
    requestConfig.headers = {};
  }

  requestConfig.headers = {
    ...requestConfig.headers,
    'Source-Id': '2',
    Time: Date.now(),
    Authorization: `Bearer ${tokenStore.token}`,
    'K-Engage-Manager-Version': process.env.VUE_APP_VERSION,
    'User-Id': tokenStore.b2cId,
    'Session-Id': usePostHogEvents().postHogSessionId(),
  };

  return requestConfig;
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorStore = useErrorStore();

    errorStore.setErrorCode(error.response?.status);

    const isBusinessError =
      axios.isAxiosError(error) &&
      ResponseError.isABusinessError(error.response?.data);

    if (isBusinessError) {
      throw new ResponseError(
        error.response?.status || 400,
        error.response?.data as BusinessError,
      );
    }

    throw error;
  },
);

export default api;

import { defineStore } from 'pinia';
import publicDisplaysService from '@/shared/services/publicDisplaysService/publicDisplaysService';
import {
  NewPublicDisplay,
  PinInformation,
  PublicDisplaysRequest,
  PublicDisplaysResponse,
  PublicDisplaysWidgets,
} from '@/shared/types/publicDisplays';
import { GetRequestParameters } from '@/shared/types/requests';

interface State {
  pinInformation: PinInformation;
  randomPinInformation: PinInformation;
  publicDisplaysList: PublicDisplaysResponse;
}

export default defineStore({
  id: 'publicDisplays',

  state: (): State => ({
    pinInformation: {} as PinInformation,
    randomPinInformation: {} as PinInformation,
    publicDisplaysList: {} as PublicDisplaysResponse,
  }),

  actions: {
    async loadPinInformation() {
      try {
        this.pinInformation = await publicDisplaysService.getPinInformation();
      } catch (error) {
        this.pinInformation = {} as PinInformation;
      }
    },

    editPin(pin: string) {
      return publicDisplaysService.editPin(pin);
    },

    async loadRandomPinInformation() {
      try {
        const response = await publicDisplaysService.getRandomPinInformation();
        this.pinInformation = response;
      } catch (error) {
        this.pinInformation = {} as PinInformation;
      }
    },

    async togglePinPublicDisplays(activePin: boolean): Promise<void> {
      await publicDisplaysService.togglePinPublicDisplays(activePin);
    },

    async loadPublicDisplaysList(
      requestParameters: GetRequestParameters = {},
    ): Promise<void> {
      try {
        this.publicDisplaysList = (
          await publicDisplaysService.getPublicDisplaysList(requestParameters)
        ).results;
      } catch {
        this.publicDisplaysList = {} as PublicDisplaysResponse;
      }
    },

    createPublicDisplay(
      publicDisplay: NewPublicDisplay & PublicDisplaysWidgets,
    ): Promise<void> {
      return publicDisplaysService.createPublicDisplay(publicDisplay);
    },

    async editPublicDisplay(
      displayCode: string,
      publicDisplay: PublicDisplaysRequest & PublicDisplaysWidgets,
    ): Promise<void> {
      await publicDisplaysService.editPublicDisplay(displayCode, publicDisplay);
    },

    deletePublicDisplay(publicDisplayCode: string): Promise<void> {
      return publicDisplaysService.deletePublicDisplay(publicDisplayCode);
    },
  },
});

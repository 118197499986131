import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { ResponseMetadata } from '@/shared/types/generic';
import { LeaderboardUser } from '@/shared/types/leaderboard';

const createUsers = (amount: number): LeaderboardUser[] =>
  new Array(amount).fill(0).map((_, index) => ({
    position: index,
    name: `User ${index}`,
    username: `user_name_${index}`,
    urlAvatar:
      'https://api.develop.engageworkers.com/v1/storage/container/org1/image/gold-picker.png',
    points: index,
    coins: index,
  }));

export const metadata: ResponseMetadata = {
  totalItems: 10,
  lastUpdated: 1595030400000,
};

export const leaderboard: Array<LeaderboardUser> = createUsers(50)
  .sort((a, b) => b.points - a.points)
  .map((leaderboardUser, index) => ({
    ...leaderboardUser,
    position: index,
  }));

export const getLeaderboard = () => {
  return {
    metadata,
    results: {
      leaderboard,
    },
  };
};

export const getLeaderboardUser = (position = 1): LeaderboardUser => ({
  urlAvatar: '',
  name: `User ${position}`,
  coins: getRandomNumber(10),
  points: getRandomNumber(10),
  username: `user_name_${position}`,
  position,
});

import {
  ApiResponse,
  ResponseMetadata,
  Status,
  ProcessCompletionDetails,
} from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 1,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

const metricsStatusList = [
  {
    name: 'ACTIVE',
    translationKey: 'status.active',
    reference: 'status-ref-active',
  },
  {
    name: 'INACTIVE',
    translationKey: 'status.inactive',
    reference: 'status-ref-inactive',
  },
] as Status[];

const statusList = [
  {
    name: 'TEST',
    translationKey: 'status.test',
    reference: 'status-ref-test',
  },
] as Status[];

export const mockGetStatus = (component: string): ApiResponse<Status[]> => {
  if (component === 'METRIC') {
    return {
      metadata,
      results: metricsStatusList,
    };
  }
  return {
    metadata,
    results: statusList,
  };
};

export const getSurveyCompletionDetails = (): ProcessCompletionDetails => {
  return {
    motive: 'motive',
    createdAt: 1696464000000,
    isAutomatic: false,
  };
};

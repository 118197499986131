import { intervalToDuration } from 'date-fns';

const calculateDurationEventTimeInSeconds = (
  initialDate: Date,
  endDate: Date,
): number => {
  const interval = intervalToDuration({
    start: initialDate,
    end: endDate,
  });

  let seconds = 0;

  if (interval.hours) {
    seconds += interval.hours * 3600;
  }

  if (interval.minutes) {
    seconds += interval.minutes * 60;
  }

  if (interval.seconds) {
    seconds += interval.seconds;
  }

  return seconds;
};

export default { calculateDurationEventTimeInSeconds };

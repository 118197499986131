import { defineStore } from 'pinia';
import storeService from '@/shared/services/storeService/storeService';
import { PurchaseItem, StoreCategory, StoreItem } from '@/shared/types/store';
import { TableRow } from '@/shared/types/components';

export default defineStore({
  id: 'store',

  state: () => ({
    items: [] as TableRow<StoreItem>[],
    categories: [] as StoreCategory[],
    purchases: [] as TableRow<PurchaseItem>[],
  }),

  actions: {
    async loadStoreItems() {
      try {
        this.items = (await storeService.getStoreItems()).results.items;
      } catch (e) {
        this.items = [];
      }
    },

    async loadPurchaseItems() {
      try {
        this.purchases = (await storeService.getPurchases()).results;
      } catch (e) {
        this.purchases = [];
      }
    },

    async loadCategories() {
      try {
        this.categories = (
          await storeService.getCategories()
        ).results.itemTypes;
      } catch (e) {
        this.items = [];
      }
    },
  },
});

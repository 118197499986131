import {
  ApiResponse,
  ImageTypeStaticReference,
  ProcessCompletionDetails,
  ResponseMetadata,
} from '@/shared/types/generic';
import {
  ChallengeAudienceStaticReference,
  ChallengeGoalStaticReference,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
  EditChallengeRequest,
  GameDetails,
  ChallengeStatus,
  NewChallengeRequest,
  RecurrencyConfigsOptions,
  RecurrencyOption,
  LoadGameRequest,
} from '@/shared/types/challenges';
import { ChallengeParticipant, Team } from '@/shared/types/user';
import { defaultItemIllustration } from '@/shared/constants/icons';
import { ChallengeFormat, IChallenge } from '@/shared/types/wizard';
import { team } from '../teams/mockTeams';
import { mockChallengeType } from './mockChallengeTypes';
import {
  mockChallengeMode,
  mockChallengeSecondMode,
} from './mockChallengeModes';
import { mockChallengeGoal } from './mockChallengeGoals';
import { mockChallengeAudience } from './mockChallengeAudience';
import { operators, users } from '../user/mockUser';

export const metadata: ResponseMetadata = {
  totalItems: 1,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const exampleNewChallenge: NewChallengeRequest = {
  audienceStaticReference: ChallengeAudienceStaticReference.INDIVIDUAL,
  challengeModeStaticReference: ChallengeModeStaticReference.PLAYER_VS_PLAYER,
  challengeTypeStaticReference: ChallengeTypeStaticReference.FASTEST,
  challengeGoalStaticReference: ChallengeGoalStaticReference.LEAST_POINTS_WIN,
  targetAmount: 10,
  metricReference: 'a7a28dea-c9ae-49fe-a0ba-e03b7ca8ca00',
  startTime: 1696464000000,
  endTime: 1699315140000,
  challengeRewardConfig: {
    coins: 20,
  },
  name: 'name',
  description: 'description',
  illustrationReference: 'e0d885b7-7f3f-fc99-4791-914cac10aa3a',
  teamReferences: [],
  unitMeasureReference: 'cubes',
};

export const exampleEditGame: EditChallengeRequest = {
  audienceStaticReference: ChallengeAudienceStaticReference.INDIVIDUAL,
  challengeModeStaticReference: ChallengeModeStaticReference.PLAYER_VS_PLAYER,
  challengeTypeStaticReference: ChallengeTypeStaticReference.FASTEST,
  challengeGoalStaticReference: ChallengeGoalStaticReference.LEAST_POINTS_WIN,
  unitMeasureReference: 'reference 1',
  targetAmount: 10,
  metricReference: 'a7a28dea-c9ae-49fe-a0ba-e03b7ca8ca00',
  startTime: 1696464000000,
  endTime: 1699315140000,
  challengeRewardConfig: {
    coins: 20,
  },
  name: 'name',
  description: 'description',
  illustrationReference: 'e0d885b7-7f3f-fc99-4791-914cac10aa3a',
  teamReferences: [],
};

export const getChallengeCompletionDetails = (
  isAutomatic = false,
): ProcessCompletionDetails => {
  return {
    motive: 'motive',
    createdAt: 1696464000000,
    isAutomatic,
  };
};

export const exampleChallenge: IChallenge = {
  name: 'challenge',
  description: 'desc',
  duration: 86400000,
  id: 1,
  endTime: 1682636400000,
  goal: mockChallengeGoal(),
  illustration: {
    default: false,
    description: 'description',
    imageType: ImageTypeStaticReference.COVER,
    name: 'Game Covers 006.png',
    organizationId: 1,
    reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
    url: defaultItemIllustration,
  },
  metric: {
    reference: '79bc0d72-6f39-4a0f-b5a0-780d3b4eab89',
    name: '123',
  },
  audience: mockChallengeAudience(),
  mode: mockChallengeMode(),
  reference: 'b97102c6-b16b-47ac-81ad-b9d8a7916bfa',
  startTime: 1682550000000,
  statusStaticReference: ChallengeStatus.CALCULATING_RESULTS,
  targetAmount: 50,
  targetAmountReached: 0,
  minimumAmountToReach: 40,
  type: mockChallengeType(ChallengeTypeStaticReference.FASTEST),
  participationCoins: 30,
  participationPoints: 300,
  users,
  unitMeasure: {
    name: 'unitMeasure.quantity',
    reference: 'cubes',
  },
  realStartTime: 1682559000000,
  realEndTime: 1682636490000,
  hasMultipleTimezones: false,
  modeType: {
    staticReference: ChallengeFormat.COMPETITION,
    name: 'CM_1.CT_3',
  },
  editable: true,
};

export const secondExampleChallenge: IChallenge = {
  name: 'challenge',
  description: 'desc',
  duration: 86400000,
  id: 1,
  endTime: 1682636400000,
  goal: mockChallengeGoal(),
  illustration: {
    default: false,
    description: 'description',
    imageType: ImageTypeStaticReference.COVER,
    name: 'Game Covers 006.png',
    organizationId: 1,
    reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
    url: defaultItemIllustration,
  },

  metric: {
    reference: '79bc0d72-6f39-4a0f-b5a0-780d3b4eab89',
    name: '123',
  },

  audience: mockChallengeAudience(),

  mode: mockChallengeSecondMode(),

  reference: 'b97102c6-b16b-47ac-81ad-b9d8a7916bfa',

  startTime: 1682550000000,
  statusStaticReference: ChallengeStatus.CALCULATING_RESULTS,
  targetAmount: 50,
  targetAmountReached: 0,
  minimumAmountToReach: 40,
  type: mockChallengeType(ChallengeTypeStaticReference.FASTEST),
  participationCoins: 30,
  participationPoints: 300,
  users,
  unitMeasure: {
    name: 'unitMeasure.quantity',
    reference: 'cubes',
  },
  realStartTime: 1682559000000,
  realEndTime: 1682636490000,
  hasMultipleTimezones: false,
  modeType: {
    staticReference: ChallengeFormat.COMPETITION,
    name: 'CM_1.CT_3',
  },
  editable: true,
};

const listOfGames = (
  challengeStatus: ChallengeStatus,
  typeStaticReference: ChallengeTypeStaticReference,
  modeStaticReference: ChallengeModeStaticReference,
  audienceStaticReference: ChallengeAudienceStaticReference,
  goalStaticReference: ChallengeGoalStaticReference,
  begin: number,
  end: number,
  metricName?: string,
  metricReference?: string,
  teams?: Team[],
  isAutomatic?: boolean,
): Array<IChallenge> => {
  return new Array(end - begin).fill(begin, 0, end).map((_, index) => ({
    name: 'challenge',
    description: 'desc',
    duration: 86400000,
    id: index,
    endTime: 1682636400000,
    goal: mockChallengeGoal(goalStaticReference),
    illustration: {
      default: false,
      description: 'description',
      id: 26,
      imageType: ImageTypeStaticReference.COVER,
      name: 'Game Covers 006.png',
      organizationId: 1,
      reference: 'd02fd3e4-04e4-14d9-45ca-da9fef2688fc',
      url: defaultItemIllustration,
    },
    metric: {
      id: 31,
      reference: metricReference || 'metricReference',
      name: metricName || 'metricName',
    },
    minimumAmountToReach: 10,
    audience: mockChallengeAudience(audienceStaticReference),
    mode: mockChallengeMode(modeStaticReference),
    reference: String(Math.floor(Math.random() * (10000 - 1 + 1) + 1)),
    startTime: 1682550000000,
    statusStaticReference: challengeStatus,
    targetAmount: 50,
    unitMeasure: {
      name: 'unitMeasure.quantity',
      reference: 'cubes',
    },
    totalPlayers: 10,
    targetAmountReached: 0,
    type: mockChallengeType(typeStaticReference),
    participationCoins: 30,
    participationPoints: 300,
    teams: teams || [],
    realStartTime: 1682559000000,
    realEndTime: 1682636490000,
    hasMultipleTimezones: false,
    completionDetails: getChallengeCompletionDetails(isAutomatic),
    forcedCompleted: true,
    modeType: {
      staticReference: ChallengeFormat.COMPETITION,
      name: 'CM_1.CT_3',
    },
    editable: true,
  }));
};

export const challengesList = [
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.FASTEST,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    0,
    5,
  ),
  ...listOfGames(
    ChallengeStatus.ON_GOING,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    ChallengeModeStaticReference.TOGETHER_AS_TEAM,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    5,
    10,
  ),
  ...listOfGames(
    ChallengeStatus.CALCULATING_RESULTS,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.TEAM_VS_TEAM,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    10,
    15,
  ),
  ...listOfGames(
    ChallengeStatus.COMPLETED,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    15,
    20,
  ),
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    20,
    25,
  ),
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    25,
    30,
  ),
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    30,
    35,
  ),
  ...listOfGames(
    ChallengeStatus.CALCULATING_RESULTS,
    ChallengeTypeStaticReference.CLASSIC,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    35,
    40,
    'metricName',
    'metricReference',
    [team[0], team[1]],
  ),
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    ChallengeModeStaticReference.TEAM_COOPERATION,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    40,
    45,
  ),
  ...listOfGames(
    ChallengeStatus.NOT_STARTED,
    ChallengeTypeStaticReference.ACHIEVER,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    45,
    50,
  ),
  ...listOfGames(
    ChallengeStatus.ON_GOING,
    ChallengeTypeStaticReference.ACHIEVER,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    50,
    55,
  ),

  ...listOfGames(
    ChallengeStatus.CANCELED,
    ChallengeTypeStaticReference.ACHIEVER,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    55,
    60,
    'metricName',
    'metricReference',
    undefined,
    false,
  ),

  ...listOfGames(
    ChallengeStatus.CANCELED,
    ChallengeTypeStaticReference.ACHIEVER,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    60,
    65,
    'metricName',
    'metricReference',
    undefined,
    true,
  ),
];

interface LoadGameResponse {
  challenges: IChallenge[];
  numberOfChallenges: number;
  numberOfFilteredChallenges: number;
  numberOfFinished: number;
  numberOfOnGoing: number;
  numberOfScheduled: number;
  numberOfClosed: number;
  numberOfCurrent: number;
}

export const getChallengesList = (
  pagination?: LoadGameRequest,
): ApiResponse<LoadGameResponse> => {
  const page = Number(pagination?.page);
  const size = Number(pagination?.size);

  const filteredGameList = challengesList.filter(
    (game) =>
      (pagination?.types?.length === 0 ||
        pagination?.types?.includes(game.type.staticReference)) &&
      (pagination?.modes?.length === 0 ||
        pagination?.modes?.includes(game.mode.staticReference)) &&
      (pagination?.query?.length === 0 ||
        game.name?.includes(pagination?.query || '')) &&
      (pagination?.statuses?.length === 0 ||
        pagination?.statuses?.includes(game.statusStaticReference)) &&
      (pagination?.metrics?.length === 0 ||
        pagination?.metrics?.includes(game.metric?.reference || '') ||
        (pagination?.categories &&
          pagination?.categories.includes(game.category?.reference || ''))),
  );

  const startIndex = size ? (page - 1) * size : 0;
  const endIndex = size ? (page - 1) * size + size : filteredGameList.length;

  return {
    metadata,
    results: {
      challenges: pagination
        ? filteredGameList.slice(startIndex, endIndex)
        : challengesList,
      numberOfChallenges: challengesList.length,
      numberOfFilteredChallenges: filteredGameList.length,
      numberOfFinished: challengesList.filter(
        (challenge) =>
          challenge.statusStaticReference === ChallengeStatus.COMPLETED ||
          challenge.statusStaticReference === ChallengeStatus.DONE,
      ).length,
      numberOfOnGoing: challengesList.filter(
        (challenge) =>
          challenge.statusStaticReference === ChallengeStatus.ON_GOING,
      ).length,
      numberOfScheduled: challengesList.filter(
        (challenge) =>
          challenge.statusStaticReference === ChallengeStatus.NOT_STARTED ||
          challenge.statusStaticReference === ChallengeStatus.READY,
      ).length,
      numberOfClosed: challengesList.filter(
        (challenge) =>
          challenge.statusStaticReference === ChallengeStatus.COMPLETED ||
          challenge.statusStaticReference === ChallengeStatus.CANCELED ||
          challenge.statusStaticReference === ChallengeStatus.DONE,
      ).length,
      numberOfCurrent: challengesList.filter(
        (challenge) =>
          challenge.statusStaticReference ===
            ChallengeStatus.CALCULATING_RESULTS ||
          challenge.statusStaticReference === ChallengeStatus.ON_GOING,
      ).length,
    },
  };
};

export const getChallengeDetails = (): GameDetails => {
  return {
    teams: team,
    totalPlayers: 30,
    reward: {
      coins: {
        firstPlace: 50,
        secondPlace: 25,
        thirdPlace: 10,
        participation: 5,
        total: 99,
        totalPlayers: 2,
        unitRate: 10,
      },
      points: {
        firstPlace: 50,
        secondPlace: 25,
        thirdPlace: 10,
        participation: 5,
        total: 99,
        totalPlayers: 2,
        pointsDayRate: 15,
      },
    },
    users: operators,
    challengeClaimConfig: {
      recurrency: {
        name: RecurrencyConfigsOptions.DAY,
        duration: 100000,
        reference: 'ref',
      },
    },
  };
};

export const newGame: IChallenge = {
  name: 'name',
  unitMeasure: { reference: 'cubes', name: 'cubes' },
  description: 'desc',
  illustration: {
    name: 'Game Covers 011.png',
    url: defaultItemIllustration,
    description: 'description',
    organizationId: 1,
    imageType: ImageTypeStaticReference.COVER,
    reference: 'f6de043b-90b0-cc0a-d6d6-5d70c87c29ac',
    default: false,
  },
  startTime: new Date('2022-10-10T00:00').getTime(),
  endTime: new Date('2022-10-11T00:00').getTime(),
  realStartTime: new Date('2022-10-10T00:00').getTime(),
  realEndTime: new Date('2022-10-11T00:00').getTime(),
  participationCoins: 10,
  participationPoints: 20,
  category: {
    reference: '7',
    name: 'cat',
  },
  totalPlayers: 20,
  teams: [],
  users: [],
  duration:
    new Date('2022-10-11T00:00').getTime() -
    new Date('2022-10-10T00:00').getTime(),
  audience: mockChallengeAudience(),
  mode: mockChallengeMode(),
  type: mockChallengeType(),
  statusStaticReference: ChallengeStatus.CALCULATING_RESULTS,
  id: 1,
  targetAmountReached: 10,
  hasMultipleTimezones: false,
  modeType: {
    staticReference: ChallengeFormat.COMPETITION,
    name: 'CM_1.CT_3',
  },
  reference: 'ref-1',
  minimumAmountToReach: 1,
  editable: true,
};

export const createChallenge = (): ApiResponse<{
  challenges: IChallenge[];
}> => {
  challengesList.push(exampleChallenge);
  return {
    metadata,
    results: {
      challenges: challengesList,
    },
  };
};

export const getMockRecurrencyConfigs = (): ApiResponse<{
  recurrencies: RecurrencyOption[];
}> => {
  return {
    metadata,
    results: {
      recurrencies: [
        {
          name: RecurrencyConfigsOptions.DAY,
          reference: 'reference-day',
          selectable: true,
        },
        {
          name: RecurrencyConfigsOptions.WEEK,
          reference: 'reference-week',
          selectable: false,
        },
        {
          name: RecurrencyConfigsOptions.MONTH,
          reference: 'reference-month',
          selectable: false,
        },
      ],
    },
  };
};

export const getMockChallengeParticipants = (): ApiResponse<
  ChallengeParticipant[]
> => {
  return {
    metadata,
    results: [
      {
        name: 'participant 1',
        participantOrgId: 'reference-part-1',
      },
      {
        name: 'participant 2',
        participantOrgId: 'reference-part-2',
      },
      {
        name: 'participant 3',
        participantOrgId: 'reference-part-3',
      },
    ],
  };
};

import {
  ApiResponse,
  Image,
  ImageTypeStaticReference,
  ResponseMetadata,
} from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

const createChallengeImages = (amount: number): Image[] =>
  new Array(amount).fill(0).map((_, index) => ({
    id: index,
    name: `Game image ${index}`,
    url: `game-url-${index}`,
    description: `Description for the game image #${index}`,
    organizationId: 1,
    imageType: ImageTypeStaticReference.COVER,
    default: false,
    reference: `reference-${index}`,
  }));

export const getChallengeImages = (): ApiResponse<{ images: Image[] }> => ({
  metadata,
  results: {
    images: createChallengeImages(8),
  },
});

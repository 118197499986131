import qs from 'qs';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import {
  EditChallengeRequest,
  GameDetails,
  PositionChallengeDistribution,
  SchedulerChallengeConfig,
  ChallengeGoal,
  RecurrencyOption,
  ChallengeLeaderboardResponse,
  LoadGameRequest,
  ChallengeTypeStaticReference,
  ChallengeModeStaticReference,
  CreateChallengeEnabled,
  ChallengeParticipantsDetail,
  NewChallengeRequest,
} from '@/shared/types/challenges';
import {
  ChallengeFormatOptions,
  ChallengeImage,
  IChallenge,
  LoadChallengeResponse,
} from '@/shared/types/wizard';
import axios from '@/plugins/http/axios';
import { GetRequestParameters } from '@/shared/types/requests';

export default {
  getChallengeDetails(reference: string): Promise<GameDetails> {
    return axios.get(`/challenges/detail/${reference}`);
  },

  getChallengeCompletionDetails(
    reference: string,
  ): Promise<ProcessCompletionDetails> {
    return axios.get(`/challenge-completions/${reference}`);
  },

  removeChallenge(reference: string): Promise<{ message: string }> {
    return axios.delete(`/challenges/${reference}`);
  },

  finishChallenge(reference: string, motive?: string): Promise<void> {
    return axios.post(`/challenges/finish/${reference}`, { motive });
  },

  cancelChallenge(reference: string, motive: string): Promise<void> {
    return axios.post(`/challenges/cancel/${reference}`, { motive });
  },

  createChallenge(newChallenge: NewChallengeRequest): Promise<void> {
    return axios.post('/challenges', newChallenge);
  },

  editChallenge(
    challenge: EditChallengeRequest,
    reference: string,
  ): Promise<{ message: string }> {
    return axios.patch(`/challenges/${reference}`, challenge);
  },

  getChallengeGoals(
    typeReference: ChallengeTypeStaticReference,
  ): Promise<ApiResponse<{ challengeGoals: ChallengeGoal[] }>> {
    return axios.get(`/challenge-goals/challenge-type/${typeReference}`);
  },

  getChallengeDistributionCoins(
    totalCoins: number,
    challengeModeStaticReference: ChallengeModeStaticReference,
    challengeTypeStaticReference?: ChallengeTypeStaticReference,
    totalPlayers?: number,
  ): Promise<PositionChallengeDistribution> {
    return axios.post('/challenge-rewards/coins', {
      totalCoins,
      challengeModeStaticReference,
      challengeTypeStaticReference,
      totalPlayers,
    });
  },

  getChallengeDistributionPoints(
    duration: number,
    challengeModeStaticReference: ChallengeModeStaticReference,
    challengeTypeStaticReference?: ChallengeTypeStaticReference,
    totalPlayers?: number,
  ): Promise<PositionChallengeDistribution> {
    return axios.post('/challenge-rewards/points', {
      duration,
      challengeModeStaticReference,
      challengeTypeStaticReference,
      totalPlayers,
    });
  },

  getSchedulerChallengeConfig(): Promise<
    ApiResponse<SchedulerChallengeConfig[]>
  > {
    return axios.get('/challenge-scheduler-config');
  },

  getDuplicatedChallenge(challengeReference: string): Promise<IChallenge> {
    return axios.get(`/challenges/clone/${challengeReference}`);
  },

  getRecurrencyIntervals(
    duration: number,
  ): Promise<ApiResponse<{ recurrencies: RecurrencyOption[] }>> {
    return axios.get('/recurrency', {
      params: { duration },
    });
  },

  getChallengeFormats(): Promise<ChallengeFormatOptions[]> {
    return axios.get('/challenge-modes-types');
  },

  getChallengeImage(
    challengeModeStaticReference: string,
    challengeTypeStaticReference: string,
    challengeGoalStaticReference?: string,
  ): Promise<ApiResponse<ChallengeImage[]>> {
    return axios.post('/challenge-images', {
      challengeModeStaticReference,
      challengeTypeStaticReference,
      challengeGoalStaticReference,
    });
  },

  getCreationChallengeEnabled(): Promise<CreateChallengeEnabled> {
    return axios.get('/challenges/creation/enabled');
  },

  getChallenges(
    request: LoadGameRequest,
  ): Promise<ApiResponse<LoadChallengeResponse>> {
    return axios.get('/challenges', {
      params: {
        page: request.page,
        size: request.size,
        categories: request.categories,
        metrics: request.metrics,
        modes: request.modes,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        statuses: request.statuses,
        types: request.types,
        modeTypes: request.modeTypes,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  getParticipantsDetail(
    challengeReference: string,
    { page, size, orderBy }: GetRequestParameters = {},
  ): Promise<ChallengeParticipantsDetail> {
    return axios.get(`/challenges/detail/${challengeReference}/participants`, {
      params: {
        page,
        size,
        order_by: orderBy,
      },
    });
  },

  getLeaderboardDetail(
    reference: string,
    {
      page,
      size,
      orderBy,
      showIncomingRewards,
      showAsTeamLeaderboard,
      limit,
    }: GetRequestParameters = {},
  ): Promise<ApiResponse<ChallengeLeaderboardResponse>> {
    return axios.get(`/leaderboards/challenge/${reference}`, {
      params: {
        page,
        size,
        order_by: orderBy,
        limit,
        showIncomingRewards,
        showAsTeamLeaderboard,
      },
    });
  },
};

import { defineStore } from 'pinia';
import { Image, ImageTypeStaticReference } from '@/shared/types/generic';
import imagesService from '@/shared/services/imagesService/imagesService';

export default defineStore({
  id: 'images',

  state: () => ({
    images: [] as Image[],
  }),

  actions: {
    async loadImages(imageType: ImageTypeStaticReference) {
      try {
        this.images = (await imagesService.getImages(imageType)).results.images;
      } catch (e) {
        this.images = [];
      }
    },
  },
});

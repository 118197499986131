import { PermissionCode } from '@/shared/types/roles';
import useRolesStore from '@/store/roles/useRolesStore';
import { ref } from 'vue';
import useFeatures from '../useFeatures/useFeatures';
import useNavMenuData from '../useNavMenuData/useNavMenuData';

const dataCreatePermission = ref(false);
const dataDeletePermission = ref(false);
const dataExportPermission = ref(false);
const dataReadPermission = ref(false);

const storeCreatePrizePermission = ref(false);
const storeCreateCategoryPermission = ref(false);
const storeDeletePrizePermission = ref(false);
const storeDeleteCategoryPermission = ref(false);
const storeEditPermission = ref(false);
const storeReadPermission = ref(false);

const challengeCreatePermission = ref(false);
const challengeEditPermission = ref(false);
const challengeDeletePermission = ref(false);
const challengeReadPermission = ref(false);
const challengeDuplicatePermission = ref(false);
const challengeFinishNowPermission = ref(false);
const challengeCancelPermission = ref(false);

const leaderboardReadPermission = ref(false);

const dashboardReadPermission = ref(false);

const userCreatePermission = ref(false);
const userDeletePermission = ref(false);
const userEditPermission = ref(false);
const userReadPermission = ref(false);

const purchaseActivityReadPermission = ref(false);
const purchaseActivityRefundPermission = ref(false);

const teamCreatePermission = ref(false);
const teamDeletePermission = ref(false);
const teamAddUsersPermission = ref(false);
const teamEditPermission = ref(false);
const teamReadPermission = ref(false);

const metricCreatePermission = ref(false);
const metricDeletePermission = ref(false);
const metricAddToCategoryPermission = ref(false);
const metricEditPermission = ref(false);
const metricReadPermission = ref(false);

const categoryCreatePermission = ref(false);
const categoryDeletePermission = ref(false);
const categoryEditPermission = ref(false);
const categoryReadPermission = ref(false);

const publicDisplaysReadPermission = ref(false);

const surveysCreatePermission = ref(false);
const surveysEditPermission = ref(false);
const surveysDeletePermission = ref(false);
const surveysCancelPermission = ref(false);
const surveysReadPermission = ref(false);

const sitesCreatePermission = ref(false);
const sitesEditPermission = ref(false);
const sitesDeletePermission = ref(false);
const sitesReadPermission = ref(false);

const rolesCreateCustomPermission = ref(false);
const rolesEditPermissionsPermission = ref(false);
const rolesAddUsersToRolesPermission = ref(false);
const rolesReadPermission = ref(false);
const rolesDeletePermission = ref(false);

export default function useRoleLevelPermissions() {
  const { isPermissionActive, loadProfileRoleLevel } = useRolesStore();

  const setPermissions = () => {
    dataCreatePermission.value = isPermissionActive(
      PermissionCode.DATA_IMPORT_CREATE,
    );
    dataDeletePermission.value = isPermissionActive(
      PermissionCode.DATA_IMPORT_DELETE,
    );
    dataExportPermission.value = isPermissionActive(
      PermissionCode.DATA_IMPORT_EXPORT,
    );
    dataReadPermission.value = isPermissionActive(
      PermissionCode.DATA_IMPORT_READ,
    );

    storeCreatePrizePermission.value = isPermissionActive(
      PermissionCode.STORE_CREATE_PRIZE,
    );
    storeCreateCategoryPermission.value = isPermissionActive(
      PermissionCode.STORE_CREATE_CATEGORY,
    );
    storeDeleteCategoryPermission.value = isPermissionActive(
      PermissionCode.STORE_DELETE_CATEGORY,
    );
    storeDeletePrizePermission.value = isPermissionActive(
      PermissionCode.STORE_DELETE_PRIZE,
    );
    storeEditPermission.value = isPermissionActive(
      PermissionCode.STORE_EDIT_ITEMS,
    );
    storeReadPermission.value = isPermissionActive(
      PermissionCode.STORE_READ_ITEMS,
    );

    challengeCreatePermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_CREATE,
    );
    challengeEditPermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_EDIT,
    );
    challengeDeletePermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_DELETE,
    );
    challengeReadPermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_READ,
    );
    challengeDuplicatePermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_DUPLICATE,
    );
    challengeFinishNowPermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_FINISH_NOW,
    );
    challengeCancelPermission.value = isPermissionActive(
      PermissionCode.CHALLENGE_CANCEL,
    );

    userCreatePermission.value = isPermissionActive(
      PermissionCode.USERS_CREATE,
    );
    userDeletePermission.value = isPermissionActive(
      PermissionCode.USERS_DELETE,
    );
    userEditPermission.value = isPermissionActive(PermissionCode.USERS_EDIT);
    userReadPermission.value = isPermissionActive(PermissionCode.USERS_READ);

    leaderboardReadPermission.value = isPermissionActive(
      PermissionCode.LEADERBOARD_READ,
    );

    dashboardReadPermission.value = isPermissionActive(
      PermissionCode.DASHBOARD_READ,
    );

    purchaseActivityReadPermission.value = isPermissionActive(
      PermissionCode.PURCHASE_ACTIVITY_READ,
    );

    purchaseActivityRefundPermission.value = isPermissionActive(
      PermissionCode.PURCHASE_ACTIVITY_REFUND,
    );

    teamCreatePermission.value = isPermissionActive(
      PermissionCode.TEAMS_CREATE,
    );
    teamDeletePermission.value = isPermissionActive(
      PermissionCode.TEAMS_REMOVE,
    );
    teamAddUsersPermission.value = isPermissionActive(
      PermissionCode.TEAMS_ADD_USERS,
    );
    teamEditPermission.value = isPermissionActive(PermissionCode.TEAMS_EDIT);
    teamReadPermission.value = isPermissionActive(PermissionCode.TEAMS_READ);

    metricCreatePermission.value = isPermissionActive(
      PermissionCode.METRICS_CREATE,
    );
    metricDeletePermission.value = isPermissionActive(
      PermissionCode.METRICS_DELETE,
    );
    metricAddToCategoryPermission.value = isPermissionActive(
      PermissionCode.METRICS_ADD_METRICS_TO_CATEGORY,
    );
    metricEditPermission.value = isPermissionActive(
      PermissionCode.METRICS_EDIT,
    );
    metricReadPermission.value = isPermissionActive(
      PermissionCode.METRICS_READ,
    );

    categoryCreatePermission.value = isPermissionActive(
      PermissionCode.CATEGORY_CREATE,
    );
    categoryDeletePermission.value = isPermissionActive(
      PermissionCode.CATEGORY_DELETE,
    );
    categoryEditPermission.value = isPermissionActive(
      PermissionCode.CATEGORY_EDIT,
    );
    categoryReadPermission.value = isPermissionActive(
      PermissionCode.CATEGORY_READ,
    );

    publicDisplaysReadPermission.value = isPermissionActive(
      PermissionCode.PUBLIC_DISPLAYS_READ,
    );

    surveysCreatePermission.value = isPermissionActive(
      PermissionCode.SURVEYS_CREATE,
    );
    surveysEditPermission.value = isPermissionActive(
      PermissionCode.SURVEYS_EDIT,
    );
    surveysDeletePermission.value = isPermissionActive(
      PermissionCode.SURVEYS_DELETE,
    );
    surveysCancelPermission.value = isPermissionActive(
      PermissionCode.SURVEYS_CANCEL,
    );
    surveysReadPermission.value = isPermissionActive(
      PermissionCode.SURVEYS_READ,
    );

    sitesCreatePermission.value = isPermissionActive(
      PermissionCode.SITES_CREATE,
    );
    sitesEditPermission.value = isPermissionActive(PermissionCode.SITES_EDIT);
    sitesDeletePermission.value = isPermissionActive(
      PermissionCode.SITES_DELETE,
    );
    sitesReadPermission.value = isPermissionActive(PermissionCode.SITES_READ);

    rolesCreateCustomPermission.value = isPermissionActive(
      PermissionCode.ROLES_CREATE_CUSTOM,
    );
    rolesEditPermissionsPermission.value = isPermissionActive(
      PermissionCode.ROLES_EDIT_PERMISSONS,
    );
    rolesAddUsersToRolesPermission.value = isPermissionActive(
      PermissionCode.ROLES_ADD_USERS_TO_ROLES,
    );
    rolesReadPermission.value = isPermissionActive(PermissionCode.ROLES_READ);
    rolesDeletePermission.value = isPermissionActive(
      PermissionCode.ROLES_DELETE,
    );
  };

  const loadRoleLevel = async () => {
    await loadProfileRoleLevel();
    const { isFeatureEnabled } = useFeatures();
    const { updateNavMenuData } = useNavMenuData();

    updateNavMenuData(isFeatureEnabled, isPermissionActive);

    setPermissions();
  };

  return {
    dataCreatePermission,
    dataExportPermission,
    dataDeletePermission,
    dataReadPermission,
    storeCreatePrizePermission,
    storeCreateCategoryPermission,
    storeDeletePrizePermission,
    storeDeleteCategoryPermission,
    storeEditPermission,
    storeReadPermission,
    challengeCreatePermission,
    challengeEditPermission,
    challengeDeletePermission,
    challengeReadPermission,
    challengeDuplicatePermission,
    challengeFinishNowPermission,
    challengeCancelPermission,
    leaderboardReadPermission,
    dashboardReadPermission,
    purchaseActivityReadPermission,
    purchaseActivityRefundPermission,
    userCreatePermission,
    userDeletePermission,
    userEditPermission,
    userReadPermission,
    teamCreatePermission,
    teamDeletePermission,
    teamAddUsersPermission,
    teamEditPermission,
    teamReadPermission,
    metricCreatePermission,
    metricDeletePermission,
    metricAddToCategoryPermission,
    metricEditPermission,
    metricReadPermission,
    categoryCreatePermission,
    categoryDeletePermission,
    categoryEditPermission,
    categoryReadPermission,
    publicDisplaysReadPermission,
    surveysCreatePermission,
    surveysEditPermission,
    surveysDeletePermission,
    surveysCancelPermission,
    surveysReadPermission,
    sitesCreatePermission,
    sitesEditPermission,
    sitesDeletePermission,
    sitesReadPermission,
    rolesCreateCustomPermission,
    rolesEditPermissionsPermission,
    rolesAddUsersToRolesPermission,
    rolesReadPermission,
    rolesDeletePermission,
    setPermissions,
    loadRoleLevel,
  };
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="55"
    viewBox="0 0 64 55"
    fill="none"
  >
    <path
      d="M32.0003 51.2583C30.1647 50.5625 25.5846 48.6476 20.6315 45.1571C12.223 39.2341 6.74551 31.793 4.34473 23.0524C2.73062 17.1639 3.37743 12.1092 6.22107 8.44046C8.62184 5.34671 12.392 3.56982 16.5642 3.56982C21.8028 3.56982 26.581 6.33579 29.6752 11.1547L32.0003 14.7832L34.3253 11.1547C37.4137 6.33579 42.1919 3.56982 47.4363 3.56982C51.6085 3.56982 55.3787 5.34671 57.7795 8.44046C60.6231 12.1092 61.2699 17.1581 59.6558 23.0524C57.255 31.7988 51.7775 39.2341 43.369 45.1571C38.4159 48.6476 33.8416 50.5625 32.0003 51.2583Z"
      fill="#DB0505"
    />
    <path
      d="M32.0005 48.3371V54.145C32.0005 54.145 21.2553 50.7235 12.2699 41.678C7.80045 37.1812 3.76225 31.2985 1.69362 23.7596C0.650566 19.9471 0.504888 16.5543 1.04681 13.6216L6.24461 16.5025C6.17468 18.2909 6.43108 20.2461 7.00796 22.345C8.72114 28.6073 12.165 34.1564 17.2579 38.9178C18.7321 40.2979 20.3462 41.609 22.1002 42.8511C26.1268 45.7091 29.9086 47.4687 32.0063 48.3313L32.0005 48.3371Z"
      fill="#FF5A62"
    />
    <path
      d="M62.9542 13.6215L57.7564 16.5024C57.6632 13.9952 56.9406 11.8273 55.5946 10.0965C53.7182 7.67551 50.7464 6.28965 47.4366 6.28965C43.1595 6.28965 39.2262 8.59558 36.6564 12.6094L32.0063 19.8665V9.70541C35.8872 3.65018 41.7959 0.855469 47.4424 0.855469C54.6739 0.855469 61.4625 5.44434 62.96 13.6215H62.9542Z"
      fill="#FF5A62"
    />
    <path
      d="M1.04541 13.6216L6.24321 16.5025C6.33644 13.9954 7.06483 11.8274 8.4109 10.0966C10.2872 7.67561 13.2591 6.28976 16.563 6.28976V0.861328C9.33741 0.861328 2.54881 5.45018 1.04541 13.6273V13.6216Z"
      fill="#AF0011"
    />
    <path
      d="M31.999 9.70541C28.1182 3.65018 22.2036 0.855469 16.563 0.855469V6.2839C20.8401 6.2839 24.7734 8.58982 27.3432 12.6036L31.9932 19.8607V9.69967L31.999 9.70541Z"
      fill="#AF0011"
    />
    <path
      d="M62.9532 13.6216L57.7554 16.5025C57.8253 18.2909 57.5748 20.2461 56.9979 22.345C55.2847 28.6073 51.8409 34.1564 46.748 38.9236C45.2737 40.2979 43.6596 41.6148 41.9056 42.8569C37.8733 45.7149 34.0914 47.4745 31.9995 48.3371V54.145C31.9995 54.145 42.7447 50.7235 51.7302 41.678C56.1996 37.1812 60.2378 31.2985 62.3064 23.7596C63.3494 19.9471 63.4951 16.5543 62.9532 13.6216Z"
      fill="#AF0011"
    />
  </svg>
</template>

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 2C2 0.895431 2.89543 0 4 0H15C16.1046 0 17 0.89543 17 2V16C17 17.1046 16.1046 18 15 18H14V16H15V2H4V5H2V2ZM9 18.0006C9 13.03 4.97048 9 0 9V7C6.07522 7 11 11.9256 11 18.0006H9ZM4.99974 18.0006C4.99974 15.2392 2.76116 13.0003 0 13.0003V11.0003C3.86597 11.0003 6.99974 14.1349 6.99974 18.0006H4.99974Z"
      :fill="props.color"
    />
    <path
      d="M0 14.8747C1.72582 14.8747 3.12487 16.2737 3.12487 17.9995H0.999999C0.447714 17.9995 0 17.5518 0 16.9995V14.8747Z"
      :fill="props.color"
    />
  </svg>
</template>

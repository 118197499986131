import { Category } from '@/shared/types/metrics';

const createCategories = (amount: number, type = 'individual'): Category[] =>
  new Array(amount).fill(0).map((value, index) => ({
    reference: index.toString(),
    name: `Category ${index}`,
    numberOfMetrics: [0, 1, 2, 4, 8, 10][Math.floor(Math.random() * 6)],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    createdAt: 1595030400000,
    updatedAt: 1595030400000,
    categoryType: {
      reference: type,
      name: type,
    },
  }));

const categoriesArray = createCategories(500);
categoriesArray.push({
  reference: 'other category 0',
  name: `Category 0`,
  numberOfMetrics: [1, 2, 4, 8, 10][Math.floor(Math.random() * 6)],
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  createdAt: 1595030400000,
  updatedAt: 1595030400000,
  categoryType: {
    reference: 'team',
    name: 'team',
  },
});

export const categories: Category[] = categoriesArray;

export const allCategories: Category[] = createCategories(
  250,
  'category.individual',
).concat(createCategories(250, 'category.team'));

export const getCategories = (page?: number, size?: number) => ({
  metadata: {
    totalItems: categories.length,
    lastUpdated: 1595030400000,
  },
  results: {
    categories:
      page !== undefined && size !== undefined
        ? categories.slice(page * size, page * size + size)
        : categories,
  },
});

export const getAllCategories = () => ({
  metadata: {
    totalItems: categories.length,
    lastUpdated: 1595030400000,
  },
  results: {
    categories: allCategories,
  },
});

const createCategory = (
  name: string,
  numberOfMetrics: number,
  type: string,
): Category => {
  return {
    reference: `ref-${name}`,
    name,
    numberOfMetrics,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    createdAt: 1595030400000,
    updatedAt: 1595030400000,
    categoryType: {
      reference: type,
      name: type,
    },
  };
};

export const categoriesSafetyFeatureActive: Category[] = [
  createCategory('Category-safety-feature-active-team', 1, 'team'),
  createCategory('Category-safety-feature-active-ind', 1, 'individual'),
  {
    reference: `ref-Category-no-safety-featured`,
    name: 'Category-no-safety-featured',
    numberOfMetrics: 0,
    description: 'Lorem',
    createdAt: 1595030400000,
    updatedAt: 1595030400000,
  },
];

export const categoriesSafetyFeatureInactive: Category[] = [
  createCategory('Category-safety-feature-inactive-team', 1, 'team'),
  createCategory('Category-safety-feature-inactive-ind', 1, 'individual'),
  {
    reference: 'ref-category-no-safety-feature',
    name: 'Category-no-safety-feature',
    numberOfMetrics: 0,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    createdAt: 1595030400000,
    updatedAt: 1595030400000,
  },
];

export const getMockedCategories = (
  safetyFeature: string | undefined | null,
  categoryType?: string | undefined | null,
) => {
  if (safetyFeature === 'true') {
    if (categoryType === 'INDIVIDUAL') {
      return {
        categories: categoriesSafetyFeatureActive.filter(
          (category) => category.categoryType?.name !== 'team',
        ),
      };
    }
    if (categoryType === 'TEAM') {
      return {
        categories: categoriesSafetyFeatureActive.filter(
          (category) => category.categoryType?.name !== 'individual',
        ),
      };
    }
    return {
      categories: categoriesSafetyFeatureActive,
    };
  }

  if (safetyFeature === 'false') {
    if (categoryType === 'INDIVIDUAL') {
      return {
        categories: categoriesSafetyFeatureInactive.filter(
          (category) => category.categoryType?.name !== 'team',
        ),
      };
    }
    if (categoryType === 'TEAM') {
      return {
        categories: categoriesSafetyFeatureInactive.filter(
          (category) => category.categoryType?.name !== 'individual',
        ),
      };
    }
    return {
      categories: categoriesSafetyFeatureInactive,
    };
  }
  return categoriesSafetyFeatureActive.concat(categoriesSafetyFeatureInactive);
};

export const getSimplifiedCategories = () => ({
  metadata: {
    totalItems: 2,
    lastUpdated: 1595030400000,
  },
  results: {
    categories: [
      { name: 'category 1', reference: 'ref-category-1' },
      { name: 'category 2', reference: 'ref-category-2' },
    ],
  },
});

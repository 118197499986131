import { PositionChallengeDistribution } from '@/shared/types/challenges';

export default (): PositionChallengeDistribution => ({
  participation: 50,
  firstPlace: 1000,
  secondPlace: 500,
  thirdPlace: 50,
  total: 2900,
  totalPlayers: 30,
});

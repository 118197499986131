import { File, FileStatus, LoadFilesResponse } from '@/shared/types/files';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 1,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};
export const getMockFile = (fileId: number): File => {
  return {
    id: fileId,
    reference: 'reference',
    name: 'FileName',
    rows: 100,
    size: '20MB',
    uploadedAt: 1667350740000,
    uploadedBy: 'User',
    status: { id: FileStatus.UPLOADED, name: 'Uploaded' },
  };
};

const listOfFiles = (): Array<File> => {
  return new Array(50).fill(0, 0, 50).map((_, index) => getMockFile(index));
};

export const getFiles = (): ApiResponse<LoadFilesResponse> => {
  return {
    metadata,
    results: {
      importFiles: listOfFiles(),
      numberOfImportedData: 0,
      numberOfImportedDataFiltered: 0,
    },
  };
};

import {
  ApiResponse,
  Image,
  ImageTypeStaticReference,
} from '@/shared/types/generic';
import axios from '@/plugins/http/axios';

export default {
  getImages(
    imageType: ImageTypeStaticReference,
    organizationId?: number,
  ): Promise<ApiResponse<{ images: Image[] }>> {
    return axios.get('/images', {
      params: { organizationId, imageType },
    });
  },
};

import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import {
  DashboardMetricChart,
  DashboardMetricChartDateInterval,
  ChallengeKpisResponse,
} from '@/shared/types/dashboard';
import { IChallenge } from '@/shared/types/wizard';
import { exampleChallenge } from '../challenges/mockChallenges';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: 1595030400000,
};

export const exampleChallenges: IChallenge[] = [
  {
    ...exampleChallenge,
  },
  {
    ...exampleChallenge,
  },
];

export const mockGetChallengesData = (): ApiResponse<{
  challenges: IChallenge[];
  totalOfChallenges: number;
}> => {
  return {
    metadata,
    results: {
      challenges: exampleChallenges,
      totalOfChallenges: 10,
    },
  };
};

export const mockGetChallengesNewWizardData = (): ApiResponse<{
  challenges: IChallenge[];
  totalOfChallenges: number;
}> => {
  return {
    metadata,
    results: {
      challenges: exampleChallenges as IChallenge[],
      totalOfChallenges: 10,
    },
  };
};

export const mockGetChallengeKpis = (): ChallengeKpisResponse => {
  return {
    totalNumberOfParticipants: 10,
    moneyInvested: 100,
    moneySpent: 1000,
    averagePerHour: 12,
    averagePerDay: 22,
    mostProductiveDay: 1717580406000,
    leastProductiveDay: 1744976000000,
  };
};

export const getMockedMetricChart = (): DashboardMetricChart => {
  return {
    histograms: [
      {
        histogramPoints: [
          { x: 1719665907615, y: 2.6 },
          { x: 1720344307615, y: 5.9 },
          { x: 1725022707615, y: 9.0 },
          { x: 1725701107615, y: 26.4 },
          { x: 1728379507615, y: undefined },
          { x: 1731057907615, y: 70.7 },
          { x: 1733736307615, y: undefined },
          { x: 1736414707615, y: 182.2 },
          { x: 1739093107615, y: 48.7 },
          { x: 1741771507615, y: 18.8 },
          { x: 1744449907615, y: 6.0 },
          { x: 1747449907615, y: 2.3 },
        ],
        participant: 'participant 1',
      },

      {
        histogramPoints: [
          { x: 1717665907615, y: 20.6 },
          { x: 1720344307615, y: 52.9 },
          { x: 1723022707615, y: 19.0 },
          { x: 1725701107615, y: 46.4 },
          { x: 1728379507615, y: 8.7 },
          { x: 1731057907615, y: 10.7 },
          { x: 1733736307615, y: 75.6 },
          { x: 1738414707615, y: 12.2 },
          { x: 1739093107615, y: 18.7 },
          { x: 1741771507615, y: 18.8 },
          { x: 1744449907615, y: 6.0 },
          { x: 1747449907615, y: 2.3 },
        ],
        participant: 'participant 2',
      },
    ],
    totalUnits: 100,
    chartDateInterval: DashboardMetricChartDateInterval.HOUR,
  };
};

export const getMockedMetricChartEmptyState = (): DashboardMetricChart => {
  return {
    histograms: [],
    totalUnits: 0,
    chartDateInterval: DashboardMetricChartDateInterval.HOUR,
  };
};

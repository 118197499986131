export enum FileStatus {
  PROCESSING = 1,
  UPLOADED = 2,
  ERROR = 3,
}

export enum FileType {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export interface File {
  id: number;
  reference: string;
  name: string;
  rows: number;
  size: string;
  uploadedAt: number;
  uploadedBy: string;
  status: { id: FileStatus; name: string };
}

export interface NuvoFileRow {
  row: number;
  transactionId: string | null;
  name: string;
  orgUserId?: string;
  orgTeamId?: string;
  startDate: string;
  endDate: string;
}

export interface NuvoFileValidationRow {
  row: number;
  transactionId: string | null;
  name: string;
  orgUserId?: string;
  orgTeamId?: string;
  startDate: number | null;
  endDate: number | null;
}

export interface RegisterData {
  row: number;
  transactionId: string | null;
  name: string;
  orgUserId?: string;
  orgTeamId?: string;
  startDate: number | null;
  endDate: number | null;
}

export interface RegisterFile {
  fileName: string;
  fileType: string;
  metrics: RegisterData[];
}

export interface FileErrorWarningListItem {
  code: number;
  message: string;
  field: string;
}

export interface FileErrorWarning {
  row: number;
  list: FileErrorWarningListItem[];
}

export interface LoadFilesRequest {
  page: number;
  size: number;
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
}

export interface LoadFilesResponse {
  importFiles: File[];
  numberOfImportedData: number;
  numberOfImportedDataFiltered: number;
}

import axios from '@/plugins/http/axios';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import {
  Survey,
  NewSurveyRequest,
  SurveyQuestion,
  SurveyData,
  SurveysResponseStatus,
  EditSurveyRequest,
} from '@/shared/types/surveys';

export default {
  getSurveys(): Promise<ApiResponse<{ surveyResponses: Survey[] }>> {
    return axios.get('/surveys');
  },

  getSurveysStatus(): Promise<SurveysResponseStatus[]> {
    return axios.get('/surveys/status');
  },

  createSurvey(newSurvey: NewSurveyRequest): Promise<void> {
    return axios.post('/surveys', newSurvey);
  },

  getSurveyAudience(): Promise<{ numberOfUsers: number }> {
    return axios.get('/surveys/users');
  },

  getSurveyQuestions(
    reference: string,
  ): Promise<{ surveyQuestions: SurveyData }> {
    return axios.get(`/surveys/${reference}`);
  },

  getSurveyCompletionDetails(
    surveyReference: string,
  ): Promise<ProcessCompletionDetails> {
    return axios.get(`/survey-completions/${surveyReference}`);
  },

  getDefaultQuestions(): Promise<ApiResponse<SurveyQuestion[]>> {
    return axios.get('/surveys/default-questions');
  },

  updatePopupFlag(popupFlag: boolean): Promise<void> {
    return axios.put(`/surveys/popup/${popupFlag}`);
  },

  editSurvey(editSurvey: EditSurveyRequest): Promise<void> {
    return axios.post(`/surveys/${editSurvey.reference}`, editSurvey);
  },

  removeSurvey(surveyReference: string): Promise<void> {
    return axios.delete(`/surveys/${surveyReference}`);
  },
};

import { Image, ProcessCompletionDetails } from '@/shared/types/generic';
import { Operator, Team } from './user';
import { UnitOfMeasure } from './metrics';

export enum ChallengeStatus {
  NOT_STARTED = 'S_6',
  READY = 'S_7',
  ON_GOING = 'S_8',
  CALCULATING_RESULTS = 'S_9',
  COMPLETED = 'S_5',
  DONE = 'S_10',
  CANCELED = 'S_16',
}

export enum ChallengeStatusId {
  ALL = 'all',
  SCHEDULED = 'scheduled',
  ON_GOING = 'ongoing',
  FINISHED = 'finished',
}

export enum Currency {
  COIN = 'COIN',
  CUSTOM = 'CUSTOM',
}

export enum TypeChallenge {
  INDIVIDUAL = 'INDIVIDUAL',
  TEAM = 'TEAM',
}

export enum ResultsContext {
  CHALLENGE = 'CHALLENGE',
  SURVEY = 'SURVEY',
}

export enum ChallengeAudienceStaticReference {
  INDIVIDUAL = 'A_1',
  TEAM = 'A_2',
}

export enum ChallengeGoalStaticReference {
  TARGET = 'CG_6',
  MOST_POINTS_WIN = 'CG_4',
  LEAST_POINTS_WIN = 'CG_5',
}

export enum ChallengeModeStaticReference {
  TEAM_DUEL = 'CM_5',
  TEAM_VS_TEAM = 'CM_4',
  TOGETHER_AS_TEAM = 'CM_3',
  TEAM_COOPERATION = 'CM_6',
  PLAYER_VS_PLAYER = 'CM_1',
  SINGLE_PLAYER = 'CM_7',
}

export enum ChallengeTypeStaticReference {
  CLASSIC = 'CT_3',
  FASTEST = 'CT_4',
  REACHED_AS_TEAM = 'CT_5',
  ACHIEVER = 'CT_6',
}

export enum ChallengeDuelsResults {
  TIE = 'tie',
  WIN = 'win',
  LOSE = 'lose',
}

export enum ChallengeDuelsResultsOngoing {
  'win' = '1',
  'lose' = '2',
  'tie' = '3',
}

export interface ChallengeType {
  imageUrl: string;
  promotes: string[];
  staticReference: ChallengeTypeStaticReference;
}

export interface ChallengeMode {
  imageUrl: string;
  promotes: string[];
  staticReference: ChallengeModeStaticReference;
}

export interface ChallengeGoal {
  staticReference: ChallengeGoalStaticReference;
}

export interface ChallengeAudience {
  name: string;
  imageUrl: string;
  reference: string;
  description: string;
  staticReference: ChallengeAudienceStaticReference;
}

export enum RecurrencyConfigsOptions {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface RecurrencyOption {
  name: RecurrencyConfigsOptions;
  reference: string;
  selectable: boolean;
}

export interface User {
  name: string;
  value: string;
}

export interface NewChallengeRequest {
  audienceStaticReference: string;
  challengeModeStaticReference: string;
  challengeTypeStaticReference: string;
  challengeGoalStaticReference?: string;
  targetAmount?: number;
  metricReference?: string;
  categoryReference?: string;
  unitMeasureReference: string;
  startTime: number;
  endTime: number;
  name: string;
  description: string;
  illustrationReference: string;
  teamReferences?: string[];
  audienceUserReferences?: string[];
  challengeClaimConfig?: {
    claimRecurrencyReference?: string;
  };
  challengeRewardConfig: {
    coins?: number;
    unitCoinRate?: number;
  };
}

export interface EditChallengeRequest {
  audienceStaticReference: string;
  challengeModeStaticReference: ChallengeModeStaticReference;
  challengeTypeStaticReference: ChallengeTypeStaticReference;
  challengeGoalStaticReference?: ChallengeGoalStaticReference;
  unitMeasureReference: string;
  targetAmount?: number;
  metricReference?: string;
  categoryReference?: string;
  startTime: number;
  endTime: number;
  name: string;
  description: string;
  illustrationReference: string;
  teamReferences?: string[];
  audienceUserReferences?: string[];
  challengeClaimConfig?: {
    claimRecurrencyReference: string;
  };
  challengeRewardConfig: {
    coins?: number;
    unitCoinRate?: number;
  };
}

export enum DatePeriod {
  ZERO = 0,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
}

export interface ChallengeDistributionPoints {
  participationPoints: number;
  firstPlacePoints: number;
  secondPlacePoints: number;
  thirdPlacePoints: number;
  totalPoints: number;
  totalPlayers: number;
}

export interface PositionChallengeDistribution {
  total: number;
  totalPlayers: number;
  firstPlace?: number;
  secondPlace?: number;
  thirdPlace?: number;
  win?: number;
  loss?: number;
  tie?: number;
  participation: number;
}

export interface SchedulerChallengeConfig {
  enrollmentTime: number;
  delayTime: number;
  autoclaimTime: number;
  minDuration: number;
}

export interface PrizeDistribution {
  coins: number;
  points: number;
}

export interface ChallengeMetric {
  id: number;
  reference: string;
  name: string;
}

export interface GameReward {
  coins: {
    firstPlace?: number;
    secondPlace?: number;
    thirdPlace?: number;
    win?: number;
    loss?: number;
    participation: number;
    total: number;
    totalPlayers: number;
    unitRate?: number;
  };
  points: {
    firstPlace?: number;
    secondPlace?: number;
    thirdPlace?: number;
    win?: number;
    loss?: number;
    participation: number;
    total: number;
    totalPlayers: number;
    pointsDayRate?: number;
  };
}

export interface GameCategory {
  id: number;
  reference: string;
  name: string;
}

export interface ClaimRecurrency {
  name: RecurrencyConfigsOptions;
  duration: number;
  reference: string;
}

export interface GameDetails {
  teams: Team[];
  reward: GameReward;
  totalPlayers: number;
  users: Operator[];
  challengeClaimConfig: {
    recurrency: ClaimRecurrency;
  };
}

export interface Game extends Partial<GameDetails> {
  category?: GameCategory;
  description: string;
  duration: number;
  endTime: number;
  goal?: ChallengeGoal;
  id: number;
  illustration: Image;
  metric?: ChallengeMetric;
  mode: ChallengeMode;
  audience: ChallengeAudience;
  name: string;
  reference: string;
  startTime: number;
  statusStaticReference: ChallengeStatus;
  unitMeasure: UnitOfMeasure;
  targetAmount?: number;
  type: ChallengeType;
  participationCoins: number;
  participationPoints: number;
  targetAmountReached: number;
  minimumAmountToReach: number;
  metricCoinRatio?: number;
  realStartTime: number;
  realEndTime: number;
  hasMultipleTimezones: boolean;
  forcedCompleted?: boolean;
  completionDetails?: ProcessCompletionDetails;
}

export interface GamesPrizeDistributionModel {
  modeStaticReference: ChallengeModeStaticReference;
  coins?: number;
  points: number;
  typeStaticReference: ChallengeTypeStaticReference;
  currencyAmount: number;
  totalPlayers: number;
  startDate: Date;
  endDate: Date;
  teams: Team[];
}

export interface LoadGameRequest {
  page: number;
  size: number;
  categories?: string[];
  metrics?: string[];
  modes?: ChallengeModeStaticReference[];
  modeTypes?: string[];
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
  statuses?: ChallengeStatus[];
  types?: ChallengeTypeStaticReference[];
}

export interface ChallengeLeaderboardUser {
  position: number;
  name: string;
  username: string;
  urlAvatar: string;
  units?: number;
  points: number;
  coins: number;
  result?: string;
  incomingUnits?: number;
  incomingCoins?: number;
  incomingPoints?: number;
}

export interface ChallengeLeaderboardResponse {
  userLeaderboards: ChallengeLeaderboardUser[];
  lastUpdateDate: number;
  nextClaimCycleDate?: number;
  totalUnits?: number;
  totalCoinsGiven?: number;
  totalCoinsIncoming?: number;
}

export interface GamesWizardSidebarStep {
  title: string;
  subtitle: string;
  imageUrl: string;
}

export enum GamesWizardStep {
  AUDIENCE = 1,
  MODE = 2,
  TYPE = 3,
  GOAL = 4,
  DURATION = 5,
  PRIZE = 6,
  SUMMARY = 7,
}

export interface CreateChallengeEnabled {
  challengeCreationEnabled: boolean;
  hasMetrics: boolean;
  hasWorkers: boolean;
}

export interface ChallengeEmptyStateProps {
  hasMetrics: boolean;
  hasWorkers: boolean;
}

export interface Participants {
  name: string;
  reference: string;
  avatar: string;
  utcOffset?: string;
}
export interface ChallengesParticipants {
  participants: Array<Participants>;
  isDifferentTimezone: boolean;
}

export interface ChallengeParticipantsTeamsDetail {
  name: string;
  timezoneDifference: number;
  reference: string;
  avatar: string;
  numberOfParticipants: number;
  participants: Array<Participants>;
}

export interface ChallengeParticipantsDetail {
  numberOfParticipants: number;
  numberOfTeams?: number;
  timezoneDifference: number;
  utcOffset: string;
  participants?: Array<Participants>;
  teams?: Array<ChallengeParticipantsTeamsDetail>;
}

export interface ChallengeErrors {
  name: string;
  startDate: string;
  startTime: string;
  coins: string;
  participants: string[];
  metricCoinRatio?: string;
  recurrencyConfig?: string;
  goalRelated: {
    metric?: string;
    unit?: string;
    amount?: string;
  };
}

import { ManagerPostHog } from '@/shared/types/posthog';
import useTokenStore from '@/store/token/useTokenStore';
import { usePostHog } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export default function usePostHogEvents() {
  const { orgId, b2cId } = useTokenStore();

  const postHogParams: ManagerPostHog = {
    orgId,
    b2cId,
  };

  const postHog = usePostHog(postHogParams);

  return postHog;
}

<script setup lang="ts">
import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: GRAY_700,
});
</script>
<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 17C6.77614 17 7 16.7761 7 16.5C7 16.2239 6.77614 16 6.5 16C6.22386 16 6 16.2239 6 16.5C6 16.7761 6.22386 17 6.5 17ZM6.5 19C7.88071 19 9 17.8807 9 16.5C9 15.1193 7.88071 14 6.5 14C5.11929 14 4 15.1193 4 16.5C4 17.8807 5.11929 19 6.5 19Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5 17C13.7761 17 14 16.7761 14 16.5C14 16.2239 13.7761 16 13.5 16C13.2239 16 13 16.2239 13 16.5C13 16.7761 13.2239 17 13.5 17ZM13.5 19C14.8807 19 16 17.8807 16 16.5C16 15.1193 14.8807 14 13.5 14C12.1193 14 11 15.1193 11 16.5C11 17.8807 12.1193 19 13.5 19Z"
      :fill="props.color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.000976562 0H1.25388C2.75447 0 4.02425 1.10874 4.22651 2.59564L5.25576 10.1621C5.32333 10.6588 5.74827 11.0288 6.24959 11.0273L13.9428 11.0046C14.4002 11.0032 14.7984 10.6917 14.9097 10.2481L16.2189 5.03247L6.00122 5.03247V3.03247H16.2189C17.5209 3.03247 18.4757 4.25665 18.1588 5.5194L16.8495 10.735C16.5154 12.0659 15.3209 13.0005 13.9487 13.0046L6.25549 13.0273C4.75154 13.0317 3.47673 11.9219 3.27401 10.4317L2.24476 2.86521C2.17734 2.36958 1.75408 2 1.25388 2H0.000976562V0Z"
      :fill="props.color"
    />
  </svg>
</template>

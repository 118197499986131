import { defaultItemIllustration } from '@/shared/constants/icons';
import {
  ChallengeMode,
  ChallengeModeStaticReference,
} from '@/shared/types/challenges';
import { ResponseMetadata } from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const mockChallengeMode = (
  staticReference = ChallengeModeStaticReference.PLAYER_VS_PLAYER,
): ChallengeMode => ({
  promotes: ['productivity', 'team_building'],
  imageUrl: defaultItemIllustration,
  staticReference,
});

export const mockChallengeSecondMode = (
  staticReference = ChallengeModeStaticReference.TOGETHER_AS_TEAM,
): ChallengeMode => ({
  promotes: ['productivity', 'team_building'],
  imageUrl: defaultItemIllustration,
  staticReference,
});

import { defineStore } from 'pinia';

import {
  CreateSiteRequestParameters,
  EditSitesRequestBody,
  SiteLocation,
  Sites,
  Timezone,
  SitesSimplified,
} from '@/shared/types/settings';
import sitesService from '@/shared/services/settingsService/sitesService';
import { GetRequestParameters } from '@/shared/types/requests';

export default defineStore({
  id: 'sites',

  state: () => ({
    sites: [] as Sites[],
    siteLocation: [] as SiteLocation[],
    isLoadingLocation: false,
    sitesSimplified: [] as SitesSimplified[],
    siteTimezone: {} as Timezone,
    paginatedSites: [] as Sites[],
    counters: {
      numberOfSites: 0,
    },
  }),

  actions: {
    removeSite(reference: string) {
      return sitesService.removeSites(reference);
    },

    async loadSites() {
      try {
        this.sites = (await sitesService.getSites()).results.sites;
      } catch (e) {
        this.sites = [];
      }
    },

    async getSites(
      getSitesRequestParameters: GetRequestParameters = {},
    ): Promise<Sites[]> {
      try {
        return (await sitesService.getSites(getSitesRequestParameters)).results
          .sites;
      } catch (e) {
        return [];
      }
    },

    async getSitesSimplified(disableFilter?: boolean): Promise<void> {
      try {
        this.sitesSimplified = (
          await sitesService.getSitesSimplified(disableFilter)
        ).results.sites;
      } catch (e) {
        this.sitesSimplified = [];
      }
    },

    async createSite(newSite: CreateSiteRequestParameters): Promise<void> {
      this.cleanSiteTimezone();
      return sitesService.createSite(newSite);
    },

    async getSiteLocation(city: string): Promise<void> {
      try {
        this.isLoadingLocation = true;
        this.siteLocation = (
          await sitesService.getLocations(city)
        ).results.locations;
        this.isLoadingLocation = false;
      } catch (e) {
        this.isLoadingLocation = false;
        this.siteLocation = [];
      }
    },

    async getTimezone(lat: number, long: number): Promise<void> {
      try {
        this.siteTimezone = await sitesService.getTimezone(lat, long);
        this.cleanSiteLocation();
      } catch (e) {
        this.siteTimezone = {} as Timezone;
      }
    },

    cleanSiteLocation(): void {
      this.siteLocation = [];
    },

    cleanSiteTimezone(): void {
      this.siteTimezone = { ianaTimezone: '', utcOffset: '' };
    },

    async editSites(
      reference: Sites['reference'],
      requestBody: EditSitesRequestBody,
    ): Promise<void> {
      return sitesService.editSites(reference, requestBody);
    },

    async loadCounters(): Promise<void> {
      const { numberOfSites } = await sitesService.getCounters();
      this.counters.numberOfSites = numberOfSites;
    },
  },
});

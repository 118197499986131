<script setup lang="ts">
import { v4 } from 'uuid';

const uuid = v4();
</script>

<template>
  <svg
    width="157"
    height="109"
    viewBox="0 0 157 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0797 59.9048C14.6723 59.9048 13.265 59.3488 11.8576 58.5149C9.60587 56.8472 8.19852 54.0675 8.76146 51.2879L10.4503 40.4473L2.85058 32.6643C0.880284 30.7186 0.0358718 27.661 1.16175 24.8813C2.00617 22.1017 4.25793 20.4339 7.07264 19.878L17.7685 18.2102L22.5535 8.48147C23.6794 5.97979 26.2126 4.31201 29.0273 4.31201C31.8421 4.31201 34.3753 5.97979 35.7826 8.48147L40.2862 18.2102L50.7006 19.878C53.5153 20.1559 55.7671 22.1017 56.6115 24.8813C57.4559 27.661 56.8929 30.4406 54.9226 32.6643L47.3229 40.4473L49.0118 51.2879C49.5747 54.0675 48.4488 57.1251 45.9156 58.5149C43.6638 60.1827 40.8491 60.1827 38.3159 59.0709L29.0273 53.7896L19.7388 58.7929C18.6129 59.6268 17.4871 59.9048 16.0797 59.9048ZM29.0273 9.87129C28.7459 9.87129 27.9015 9.87129 27.62 10.9831L24.946 16.5424L22.2721 22.1017C21.7091 22.9356 20.8647 23.4915 20.0203 23.4915L7.91705 25.4373C7.07264 25.4373 6.79117 26.2711 6.5097 26.5491C6.5097 26.8271 6.22823 27.661 7.07264 28.4949L15.7982 37.1117C16.3612 37.6677 16.6426 38.5016 16.6426 39.6134L14.6723 51.8438C14.3909 52.9557 15.2353 53.5116 15.5168 53.7896C15.7982 54.0675 16.3612 54.3455 17.2056 53.7896L27.9015 47.9523C28.7459 47.3964 29.8718 47.3964 30.7162 47.9523L41.4121 53.7896C42.2565 54.0675 42.8194 53.7896 43.1009 53.7896C43.3824 53.5116 43.9453 52.9557 43.9453 51.8438L41.975 39.6134C41.6935 38.7795 41.975 37.9456 42.8194 37.1117L51.545 28.4949C51.8265 27.9389 51.545 26.8271 51.545 26.5491C51.545 26.2711 50.9821 25.4373 50.1376 25.4373L38.0344 23.7695C37.19 23.7695 36.3456 22.9356 35.7826 22.1017L30.4347 10.9831C30.1532 9.87129 29.3088 9.87129 29.0273 9.87129Z"
      fill="#F6F6F6"
    />
    <path
      d="M122.277 98.6885C121.573 98.6885 120.869 98.4105 120.166 97.9936C119.04 97.1597 118.336 95.77 118.618 94.3802L119.462 88.9601L115.662 85.0687C114.677 84.0959 114.255 82.5671 114.818 81.1774C115.24 79.7876 116.366 78.9537 117.773 78.6758L123.121 77.8419L125.513 72.9777C126.076 71.7269 127.343 70.8931 128.75 70.8931C130.157 70.8931 131.424 71.7269 132.128 72.9777L134.379 77.8419L139.586 78.6758C140.994 78.8148 142.12 79.7876 142.542 81.1774C142.964 82.5671 142.682 83.9569 141.697 85.0687L137.898 88.9601L138.742 94.3802C139.023 95.77 138.461 97.2987 137.194 97.9936C136.068 98.8275 134.661 98.8275 133.394 98.2715L128.75 95.631L124.106 98.1326C123.543 98.5495 122.98 98.6885 122.277 98.6885ZM128.75 73.6726C128.609 73.6726 128.187 73.6726 128.046 74.2285L125.373 79.7876C125.091 80.2045 124.669 80.4825 124.247 80.4825L118.195 81.4553C117.773 81.4553 117.632 81.8723 117.492 82.0112C117.492 82.1502 117.351 82.5671 117.773 82.9841L122.136 87.2924C122.417 87.5703 122.558 87.9872 122.558 88.5432L121.573 94.6581C121.432 95.2141 121.854 95.492 121.995 95.631C122.136 95.77 122.417 95.9089 122.839 95.631L128.187 92.7125C128.609 92.4345 129.172 92.4345 129.595 92.7125L134.942 95.631C135.364 95.77 135.646 95.631 135.787 95.631C135.927 95.492 136.209 95.2141 136.209 94.6581L135.224 88.5432C135.083 88.1262 135.224 87.7093 135.646 87.2924L140.009 82.9841C140.149 82.7061 140.009 82.1502 140.009 82.0112C140.009 81.8723 139.727 81.4553 139.305 81.4553L133.253 80.6215C132.831 80.6215 132.409 80.2045 132.128 79.7876L129.454 74.2285C129.313 73.6726 128.891 73.6726 128.75 73.6726Z"
      fill="#F6F6F6"
    />
    <g :filter="`url(#${uuid}_filter0_d_4510_19965)`">
      <circle
        cx="71.1692"
        cy="62.6294"
        r="31"
        :fill="`url(#${uuid}_paint0_linear_4510_19965)`"
      />
      <g :filter="`url(#${uuid}_filter1_i_4510_19965)`">
        <circle
          cx="71.1692"
          cy="62.6294"
          r="23.6055"
          :fill="`url(#${uuid}_paint1_radial_4510_19965)`"
        />
      </g>
      <g :filter="`url(#${uuid}_filter2_d_4510_19965)`">
        <path
          d="M68.5389 64.3332L62.8777 54.5549C62.0093 53.0431 60.0793 52.5284 58.5675 53.3969C57.0558 54.2654 56.5411 56.1953 57.4096 57.7071L63.0707 67.4854L68.7319 77.2638C69.3109 78.2931 70.3723 78.8399 71.466 78.8399C72.0128 78.8399 72.5596 78.7112 73.0421 78.4217C74.5539 77.5533 75.0685 75.6233 74.2 74.1115L68.5389 64.3332Z"
          :fill="`url(#${uuid}_paint2_linear_4510_19965)`"
        />
        <path
          d="M83.4347 53.0394C83.2096 53.0073 82.9844 52.9751 82.7593 52.9751H71.4691C69.7322 52.9751 68.3169 54.3904 68.3169 56.1273C68.3169 57.8643 69.7322 59.2796 71.4691 59.2796H77.2911L74.364 64.3296C73.1417 66.3882 74.7178 69.1222 77.0981 69.0579C78.1917 69.0579 79.2532 68.4789 79.8322 67.4818L85.4933 57.7034C85.4933 57.7034 85.5898 57.5104 85.6542 57.3818C86.4583 55.6127 85.3325 53.3932 83.4347 53.0073V53.0394Z"
          :fill="`url(#${uuid}_paint3_linear_4510_19965)`"
        />
      </g>
    </g>
    <g :filter="`url(#${uuid}_filter3_d_4510_19965)`">
      <circle
        cx="128.648"
        cy="25.3772"
        r="20.6941"
        :fill="`url(#${uuid}_paint4_linear_4510_19965)`"
      />
      <g :filter="`url(#${uuid}_filter4_i_4510_19965)`">
        <circle
          cx="128.649"
          cy="25.377"
          r="15.7579"
          :fill="`url(#${uuid}_paint5_radial_4510_19965)`"
        />
      </g>
      <g :filter="`url(#${uuid}_filter5_d_4510_19965)`">
        <path
          d="M126.893 26.5157L123.113 19.9882C122.534 18.979 121.245 18.6354 120.236 19.2152C119.227 19.7949 118.883 21.0832 119.463 22.0924L123.242 28.62L127.021 35.1475C127.408 35.8346 128.117 36.1997 128.847 36.1997C129.212 36.1997 129.577 36.1138 129.899 35.9205C130.908 35.3408 131.251 34.0525 130.672 33.0433L126.893 26.5157Z"
          :fill="`url(#${uuid}_paint6_linear_4510_19965)`"
        />
        <path
          d="M136.836 18.9765C136.686 18.9551 136.536 18.9336 136.385 18.9336H128.849C127.689 18.9336 126.744 19.8784 126.744 21.0379C126.744 22.1974 127.689 23.1421 128.849 23.1421H132.735L130.781 26.5133C129.965 27.8875 131.017 29.7126 132.606 29.6697C133.336 29.6697 134.045 29.2832 134.431 28.6176L138.211 22.09C138.211 22.09 138.275 21.9612 138.318 21.8753C138.855 20.6943 138.103 19.2127 136.836 18.9551V18.9765Z"
          :fill="`url(#${uuid}_paint7_linear_4510_19965)`"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${uuid}_filter0_d_4510_19965`"
        x="29.8848"
        y="25.6753"
        width="82.5688"
        height="82.5688"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.33028" />
        <feGaussianBlur stdDeviation="5.1422" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0.184314 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4510_19965"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4510_19965"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter1_i_4510_19965`"
        x="47.5637"
        y="39.0239"
        width="47.2109"
        height="49.3761"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.16514" />
        <feGaussianBlur stdDeviation="1.8945" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.691667 0 0 0 0 0.415 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_4510_19965"
        />
      </filter>
      <filter
        :id="`${uuid}_filter2_d_4510_19965`"
        x="51.5749"
        y="49.1861"
        width="39.7549"
        height="36.6904"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.62385" />
        <feGaussianBlur stdDeviation="2.70642" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.766667 0 0 0 0 0.414 0 0 0 0 0 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4510_19965"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4510_19965"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter3_d_4510_19965`"
        x="101.089"
        y="0.708419"
        width="55.1189"
        height="55.1189"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.89068" />
        <feGaussianBlur stdDeviation="3.43268" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0.184314 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4510_19965"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4510_19965"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter4_i_4510_19965`"
        x="112.891"
        y="9.61914"
        width="31.5159"
        height="32.961"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.44534" />
        <feGaussianBlur stdDeviation="1.26467" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.691667 0 0 0 0 0.415 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_4510_19965"
        />
      </filter>
      <filter
        :id="`${uuid}_filter5_d_4510_19965`"
        x="115.568"
        y="16.4042"
        width="26.5385"
        height="24.4928"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.08401" />
        <feGaussianBlur stdDeviation="1.80668" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.766667 0 0 0 0 0.414 0 0 0 0 0 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4510_19965"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4510_19965"
          result="shape"
        />
      </filter>
      <linearGradient
        :id="`${uuid}_paint0_linear_4510_19965`"
        x1="71.4536"
        y1="93.6294"
        x2="71.4536"
        y2="31.6294"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFAE01" />
        <stop offset="1" stop-color="#FFEE80" />
      </linearGradient>
      <radialGradient
        :id="`${uuid}_paint1_radial_4510_19965`"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(71.1692 39.0239) rotate(90) scale(47.211)"
      >
        <stop stop-color="#FFE200" />
        <stop offset="1" stop-color="#FCCB01" />
      </radialGradient>
      <linearGradient
        :id="`${uuid}_paint2_linear_4510_19965`"
        x1="65.8048"
        y1="52.9751"
        x2="65.8048"
        y2="78.8399"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint3_linear_4510_19965`"
        x1="77.117"
        y1="52.9751"
        x2="77.117"
        y2="69.059"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint4_linear_4510_19965`"
        x1="128.838"
        y1="46.0713"
        x2="128.838"
        y2="4.68311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFAE01" />
        <stop offset="1" stop-color="#FFEE80" />
      </linearGradient>
      <radialGradient
        :id="`${uuid}_paint5_radial_4510_19965`"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128.649 9.61914) rotate(90) scale(31.5158)"
      >
        <stop stop-color="#FFE200" />
        <stop offset="1" stop-color="#FCCB01" />
      </radialGradient>
      <linearGradient
        :id="`${uuid}_paint6_linear_4510_19965`"
        x1="125.067"
        y1="18.9336"
        x2="125.067"
        y2="36.1997"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint7_linear_4510_19965`"
        x1="132.619"
        y1="18.9336"
        x2="132.619"
        y2="29.6704"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';
import { KudoType } from '@/shared/types/kudos';
import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';

const kudoTypeNames: KudoType['name'][] = [
  'GREAT_CO_WORKER',
  'GREAT_MENTOR',
  'TAKE_OWNERSHIP',
];

export const mockKudoType = (
  name = kudoTypeNames[getRandomNumber(kudoTypeNames.length - 1)],
): KudoType => ({
  numberOfReceived: 4,
  reference: getRandomReference(),
  name,
});

export const mockKudoTypes = (): KudoType[] =>
  kudoTypeNames.map((kudoTypeName) => mockKudoType(kudoTypeName));

export default {};

import { defineStore } from 'pinia';
import {
  NewSurveyRequest,
  Survey,
  SurveyQuestion,
  SurveysResponseStatus,
  EditSurveyRequest,
  SurveyStatus,
  SurveyData,
} from '@/shared/types/surveys';
import surveysService from '@/shared/services/surveysService/surveysService';
import { TableRow } from '@/shared/types/components';
import { ProcessCompletionDetails } from '@/shared/types/generic';

export default defineStore({
  id: 'surveys',

  state: () => ({
    surveys: [] as TableRow<Survey>[],
    surveysStatusObject: [] as SurveysResponseStatus[],
    surveyRecipients: 0,
  }),

  getters: {
    surveysByStatusMap(state): Record<SurveyStatus, Survey[]> {
      return state.surveys.reduce(
        (acc: Record<SurveyStatus, Survey[]>, survey: Survey) => {
          switch (survey.status) {
            case SurveyStatus.SCHEDULED:
              acc[SurveyStatus.SCHEDULED].push(survey);
              break;
            case SurveyStatus.LIVE:
              acc[SurveyStatus.LIVE].push(survey);
              break;
            case SurveyStatus.COMPLETED:
              acc[SurveyStatus.COMPLETED].push(survey);
              break;
            default:
              break;
          }

          return acc;
        },
        {
          [SurveyStatus.SCHEDULED]: [],
          [SurveyStatus.LIVE]: [],
          [SurveyStatus.COMPLETED]: [],
          [SurveyStatus.ALL]: this.surveys,
        },
      );
    },
  },

  actions: {
    async loadSurveys(): Promise<void> {
      try {
        this.surveys = (
          await surveysService.getSurveys()
        ).results.surveyResponses;
      } catch (error) {
        this.surveys = [];
      }
    },

    async loadSurveysStatus(): Promise<void> {
      try {
        this.surveysStatusObject = await surveysService.getSurveysStatus();
      } catch (error) {
        this.surveysStatusObject = [];
      }
    },

    createSurvey(newSurvey: NewSurveyRequest): Promise<void> {
      return surveysService.createSurvey(newSurvey);
    },

    async getSurveyAudience(): Promise<void> {
      try {
        this.surveyRecipients = (
          await surveysService.getSurveyAudience()
        ).numberOfUsers;
      } catch (error) {
        this.surveyRecipients = 0;
      }
    },

    async loadSurveyDetails(reference: string): Promise<void> {
      const surveyIndex = this.surveys.findIndex(
        (survey) => survey.reference === reference,
      );

      const promises: (
        | Promise<{ surveyQuestions: SurveyData }>
        | Promise<ProcessCompletionDetails>
      )[] = [surveysService.getSurveyQuestions(reference)];
      if (this.surveys[surveyIndex]?.forcedCompleted) {
        promises.push(surveysService.getSurveyCompletionDetails(reference));
      }

      const details = await Promise.all(promises);

      const { surveyQuestions } = details[0] as { surveyQuestions: SurveyData };

      this.surveys[surveyIndex] = {
        ...this.surveys[surveyIndex],
        defaultQuestions: surveyQuestions.surveyDefaults || [],
        customQuestions: surveyQuestions.surveyCustoms || [],
        numberOfRespondents: surveyQuestions.numberOfRespondents,
        completionDetails: details[1] as ProcessCompletionDetails,
      };
    },

    updatePopupFlag(popupFlag: boolean): Promise<void> {
      return surveysService.updatePopupFlag(popupFlag);
    },

    editSurvey(editSurvey: EditSurveyRequest): Promise<void> {
      return surveysService.editSurvey(editSurvey);
    },

    async getDefaultQuestions(): Promise<SurveyQuestion[]> {
      return (await surveysService.getDefaultQuestions()).results;
    },

    removeSurvey(reference: string): Promise<void> {
      return surveysService.removeSurvey(reference);
    },
  },
});

import axios from '@/plugins/http/axios';
import {
  PermissionType,
  RoleTypeStaticReference,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';
import {
  AddCustomRoleLevelRequest,
  EditCustomRoleLevelRequest,
  OrgRoleLevelsResponse,
  ProfileRoleLevelResponse,
  RoleLevelsCount,
  RoleLevelsSimplified,
} from '@/shared/types/roles';
import { ApiResponse } from '@/shared/types/generic';
import { PaginationParameters } from '@/shared/types/requests';
import { IManager } from '@/shared/types/user';

export default {
  getProfileRoleLevels(): Promise<ProfileRoleLevelResponse> {
    return axios.get('/profiles/role-levels-permissions');
  },

  getRoleLevelsSimplified(
    roleTypeStaticReference: RoleTypeStaticReference,
    organizationName?: string,
  ): Promise<RoleLevelsSimplified> {
    return axios.get('/role-levels/simplified', {
      params: {
        organization_name: organizationName,
        role_level_user_type_static_reference: roleTypeStaticReference,
      },
    });
  },

  getOrgRoleLevels({
    page,
    size,
    sortBy,
    orderBy,
    queryBy,
  }: PaginationParameters = {}): Promise<ApiResponse<OrgRoleLevelsResponse>> {
    return axios.get('/role-levels', {
      params: {
        page,
        size,
        sort_by: sortBy,
        order_by: orderBy,
        query_by: queryBy,
      },
    });
  },

  getCounters(): Promise<RoleLevelsCount> {
    return axios.get('/role-levels/count');
  },

  getRoleLevelPermissions(
    roleLevelReference?: string,
  ): Promise<{ permissionTypes: PermissionType[] }> {
    const params = roleLevelReference
      ? { params: { role_level_reference: roleLevelReference } }
      : {};

    return axios.get('/permission-types', params);
  },

  createCustomRoleLevel(request: AddCustomRoleLevelRequest): Promise<void> {
    return axios.post('/role-levels/custom', request);
  },

  deleteCustomRoleLevel(roleLevelReference: string): Promise<void> {
    return axios.delete(`/role-levels/${roleLevelReference}`);
  },

  editCustomRoleLevel(request: EditCustomRoleLevelRequest): Promise<void> {
    return axios.patch(`/role-levels/${request.reference}`, request);
  },

  getManagersToAssignRoleLevel(
    roleLevelReference?: string,
  ): Promise<ApiResponse<IManager[]>> {
    return axios.get(
      `/users/managers/different-role-level/${roleLevelReference}`,
    );
  },
};
